export const FETCH_SETTINGS = "fetchSettings";
export const FETCH_PAGE_DATA = "fetchPageData";
/*
  _    _
 | |  | |
 | |  | |___  ___ _ __
 | |  | / __|/ _ \ '__|
 | |__| \__ \  __/ |
  \____/|___/\___|_|


*/
export const LOGIN = "login";
export const LOGOUT = "logout";
export const GET_PROFILE = "getProfile";
export const UPDATE_PROFILE = "uploadProfile";
export const UPDATE_SUPERUSER_STATUS = "updateSuperuserStatus";
export const GET_TOKEN_FROM_LOCAL_STORE = "getTokenFromLocalStore";
export const SET_PASSWORD = "setPassword";
export const PASSWORD_RESET = "passwordReset";
export const FORGOT_PASSWORD = "forgotPassword";
export const CONFIRM_FORGOT_PASSWORD = "confirmForgotPassword";
export const GET_USERS = "getUsers";
export const GET_USER = "getUser";
export const INVITE_USER = "inviteUser";
export const INVITE_BATCH_USER = "inviteBatchUser";
export const BATCH_USER_ASSIGN = "batchUserAssign";
export const UPDATE_USER_ADMIN = "updateUserAdmin";
export const DOWNLOAD_USER_INFO = "downloadUserInfo";
export const SET_ADMIN_COMMENT = "setAdminComment";

export const GET_COURSES_BY_USER = "getCoursesByUser";

export const FETCH_COURSES = "fetchCourses";
export const FETCH_COURSE_BY_SLUG = "fetchCourseBySlug";
export const SEARCH_COURSES = "searchCourses";
export const SEARCH_COURSES_INPLACE = "searchCoursesInplace";
export const FETCH_COURSE = "fetchCourse";
export const FETCH_COURSE_NEXT_CONTENT = "fetchCourseNextContent";
export const FETCH_ENROLLMENT = "fetchEnrollment";
export const FETCH_ENROLLMENTS = "fetchEnrollments";
export const ENROLLMENT_UPDATE = "enrollmentUpdate";
export const TRANSFER_TRAINING_ACTIONS = "transferTrainingActions";
export const TRANSFER_ENROLLMENT = "transferEnrollment";
export const FETCH_ENROLLMENTS_QUESTION = "fetchEnrollmentsQuestion";
export const FETCH_COURSES_MODULES = "fetchCoursesModules";
export const FETCH_COURSES_META = "fetchCoursesMeta";
export const FETCH_PRESENTABLE_COURSE_MODULES = "fetchPresentableCourseModules";
export const FETCH_PRESENTABLE_COURSE_CONTENTS =
  "fetchPresentableCourseContents";
export const FETCH_REVIEWABLE_COURSE_MODULES = "fetchReviewalbleCourseModules";
export const FETCH_REVIEWABLE_COURSE_CONTENTS =
  "fetchReviewalbleCourseContents";
export const PUBLIC_TRAINER_CERTIFICATE = "publicTrainerCertificate";

export const FETCH_EXAM_SHEET = "fetchExamSheet";
export const VALIDATE_EXAM_ANSWER = "validateExamAnswer";
export const GO_PREVIOUS_LESSON = "goPreviousLesson";
export const FETCH_LESSON = "fetchLesson";
export const GET_NOTIFICATIONS = "getNotifications";
export const GET_MORE_NOTIFICATIONS = "getMoreNotifications";
export const READ_NOTIFICATIONS = "readNotifications";
export const SUBMIT_COURSE_FEEDBACK = "submitCourseFeedback";
export const SUBMIT_QUESTION = "submitQuestion";
export const DISABLE_EXAM_CONTENT = "disableExamContent";

export const CREATE_ROOM = "createRoom";
export const FETCH_ROOMS = "fetchRooms";
export const ROOM_USER_INFO = "roomUserInfo";
export const SEND_MESSAGE = "sendMessage";
export const FETCH_MESSAGE_BY_ROOM = "fetchMessagesByRoom";

/*
....###....########..##.....##.####.##....##
...##.##...##.....##.###...###..##..###...##
..##...##..##.....##.####.####..##..####..##
.##.....##.##.....##.##.###.##..##..##.##.##
.#########.##.....##.##.....##..##..##..####
.##.....##.##.....##.##.....##..##..##...###
.##.....##.########..##.....##.####.##....##
*/

export const FETCH_ADMIN_STATS = "fetchAdminStats";

/*
   _____
  / ____|
 | |     ___  _   _ _ __ ___  ___  ___
 | |    / _ \| | | | '__/ __|/ _ \/ __|
 | |___| (_) | |_| | |  \__ \  __/\__ \
  \_____\___/ \__,_|_|  |___/\___||___/


*/

export const GET_PACKAGES = "getPackages";
export const ADD_OR_EDIT_PACKAGES = "addPackages";
export const DELETE_PACKAGES = "deletePackages";
export const GET_COURSES = "getCourses";
export const CREATE_OR_EDIT_COURSE = "createOrEditCourse";
export const GET_COURSE_BY_SLUG = "getCourseBySlug";
export const COURSE_PERMISSION = "CoursePermission";
export const GET_COURSE_MODULES = "getCourseModules";
export const GET_COURSE_CONTENTS = "getCourseContents";
export const GET_COURSE_CONTENT_BY_ID = "getCourseContentByID";
export const DELETE_COURSE_CONTENT = "deleteCourseContent";
export const ORDER_COURSE_CONTENT = "orderCourseContent";
export const CREATE_OR_EDIT_COURSE_MODULE = "createCourseModule";
export const ORDER_MODULES = "orderModules";
export const DELETE_MODULE = "deleteModule";
export const CREATE_OR_EDIT_COURSE_CONTENT = "createOrEditCourseContent";
export const GET_DEEP_CONTENTS = "getDeepContents";
export const CREATE_OR_EDIT_LESSON_CONTENT = "createOrEditLessonContent";
export const DELETE_DEEP_CONTENT = "deleteDeepContent";
export const UPDATE_COURSE_PUBLICATION = "updateCoursePublication";
export const DELETE_COURSE = "deleteCourse";
export const MOVE_CONTENT_ACROSS_MODULE = "moveContentAcrossModule";
export const COLLECTED_QUESTION = "collectedQuestion";
export const COURSE_QUESTION = "courseQuestion";
export const COURSE_QUESTION_MOVE = "courseQuestionMove";
export const COURSE_QUESTION_ORDER = "courseQuestionOrder";
export const COURSE_QUESTION_GROUP = "courseQuestionGroup";
export const COURSE_QUESTION_GROUP_ORDER = "courseQuestionGroupOrder";
export const FETCH_COURSE_FEEDBACK_QUESTION = "courseFeedbackQuestion";
export const FETCH_COURSE_FEEDBACK_QUESTIONS = "courseFeedbackQuestions";
export const COURSE_FEEDBACK_QUESTION_ORDER = "courseFeedbackQuestionOrder";
export const FEEDBACK_REPORT = "feedbackReport";

export const FETCH_ENROLLMENT_CONTENT_INDEX = "fetchEnrollmentContentIndex";
export const FETCH_ENROLLMENT_MODULE_INDEX = "fetchEnrollmentModuleIndex";

export const MANIPULATE_PROGRESS = "manipulateProgress";

export const FETCH_COURSE_MANUALS = "fetchCourseManuals";
export const CREATE_OR_UPDATE_COURSE_MANUAL = "createOrUpdateCourseManual";
export const DELETE_COURSE_MANUAL = "deleteCourseManual";
export const ORDER_COURSE_MANUAL = "orderCourseManual";

/*
  __  __          _ _
 |  \/  |        | (_)
 | \  / | ___  __| |_  __ _
 | |\/| |/ _ \/ _` | |/ _` |
 | |  | |  __/ (_| | | (_| |
 |_|  |_|\___|\__,_|_|\__,_|


*/

export const GET_MEDIA_IMAGE = "getMediaImage";
export const GET_MEDIA_BY_SLUG = "getMediaBySlug";
export const GET_MEDIAS = "getMedias";
export const UPLOAD_MEDIA = "uploadMedia";
export const DELETE_MEDIA = "deleteMedia";

/*
   ____                        _          _   _
  / __ \                      (_)        | | (_)
 | |  | |_ __ __ _  __ _ _ __  _ ______ _| |_ _  ___  _ __  ___
 | |  | | '__/ _` |/ _` | '_ \| |_  / _` | __| |/ _ \| '_ \/ __|
 | |__| | | | (_| | (_| | | | | |/ / (_| | |_| | (_) | | | \__ \
  \____/|_|  \__, |\__,_|_| |_|_/___\__,_|\__|_|\___/|_| |_|___/
              __/ |
             |___/
*/

export const GET_ORGANIZATIONS = "getOrganizations";
export const GET_ORGANIZATION = "getOrganization";
export const CREATE_OR_UPDATE_ORGANIZATION = "createOrUpdateOrganization";
export const DELETE_ORGANIZATION = "deleteOrganization";
export const GENERATE_FACTORY_REPORT = "generateFactoryReport";
export const GENERATE_ORGANIZATION_REPORT = "generateOrganizationReport";
export const GENERATE_ALL_ORGANIZATION_REPORT = "generateAllOrganizationReport";
export const FETCH_MASTER_REPORTS = "fetchAllOrganizationReports";
export const DELETE_MASTER_REPORT = "deleteMasterReport";
export const ADD_OR_REMOVE_FACTORY_CONTACT = "addOrRemoveFactoryContact";
export const ADD_OR_REMOVE_ORG_CONTACT = "addOrRemoveOrgContact";
export const FETCH_FACTORY_VISITS = "fetchFactoryVisits";
export const FETCH_FACTORY_VISIT = "fetchFactoryVisit";
export const ADD_FACTORY_VISIT = "addFactoryVisit";
export const DELETE_FACTORY_VISIT = "deleteFactoryVisit";
export const ADD_FACTORY_VISIT_FILE = "addFactoryVisitFile";
export const DELETE_FACTORY_VISIT_FILE = "deleteFactoryVisitFile";

export const FETCH_FACTORY_VISIT_SCHEDULES = "fetchFactoryVisitSchedules";

export const FETCH_FACTORY_VISIT_STATS = "fetchFactoryVisitStats";

export const EXPORT_FACTORY_VISITS = "exportFactoryVisits";
export const FETCH_FACTORY_VISITS_EXPORTS = "fetchFactoryVisitsExports";
export const FETCH_ORG_STATS = "fetchOrgStats";

/*

   _____          _
  |  ___|_ _  ___| |_ ___  _ __ _   _
  | |_ / _` |/ __| __/ _ \| '__| | | |
  |  _| (_| | (__| || (_) | |  | |_| |
  |_|  \__,_|\___|\__\___/|_|   \__, |
                                |___/

*/
export const GET_FACTORIES = "getFactories";
export const GET_FACTORY = "getFactory";
export const GET_FACTORY_USERS = "getFactoryUsers";
export const DELETE_FACTORY = "deleteFactory";
export const CREATE_OR_UPDATE_FACTORY = "createOrUpdateFactory";
export const FACTORY_BULK_UPLOAD = "factoryBulkUpload";
export const FETCH_MASTER_PLANS = "fetchMasterPlans";
export const MASTER_PLAN_CREATE_OR_UPDATE = "masterPlanCreateOrUpdate";
export const DOWNLOAD_MASTER_PLAN = "downloadMasterPlan";

/*

    ____            _             _     ____
   / ___|___  _ __ | |_ __ _  ___| |_  |  _ \ ___ _ __ ___  ___  _ __
  | |   / _ \| '_ \| __/ _` |/ __| __| | |_) / _ \ '__/ __|/ _ \| '_ \
  | |__| (_) | | | | || (_| | (__| |_  |  __/  __/ |  \__ \ (_) | | | |
   \____\___/|_| |_|\__\__,_|\___|\__| |_|   \___|_|  |___/\___/|_| |_|


*/

export const GET_CONTACT_PERSONS = "getContactPersons";
export const GET_CONTACT_PERSON = "getContactPerson";
export const DELETE_CONTACT_PERSON = "getContactPerson";
export const CREATE_OR_UPDATE_CONTACT_PERSON = "createOrUpdateContactPerson";
export const ADD_OR_REMOVE_FACTORY_COURSE = "addOrRemoveFactoryCourse";
export const ATTACH_CONTACT_PERSON = "attachContactPerson";
export const DETACH_CONTACT_PERSON = "detachContactPerson";

/*
  _______        _       _
 |__   __|      (_)     (_)
    | |_ __ __ _ _ _ __  _ _ __   __ _
    | | '__/ _` | | '_ \| | '_ \ / _` |
    | | | | (_| | | | | | | | | | (_| |
    |_|_|  \__,_|_|_| |_|_|_| |_|\__, |
                                  __/ |
                                 |___/
*/

export const GET_TRAININGS = "getTrainings";
export const CREATE_OR_UPDATE_TRAINING = "createOrUpdateTraining";
export const GET_TRAINING = "getTraining";
export const DELETE_TRAINING = "deleteTraining";
export const ADD_USER_IN_TRAINING = "addUserInTraining";
export const DELETE_USER_FROM_TRAINING = "deleteUserFromTraining";
export const TRAINING_PERMISSION = "trainingPermission";
export const TRAINING_PERMISSION_UPDATE = "trainingPermissionUpdate";
export const GET_USERS_BY_TRAINING = "getUsersByTraining";
export const GET_COURSE_DETAILS_BY_USER = "getCourseDetailsByUserDetails";
export const DATE_WISE_REPORT = "dateWiseReport";
export const TRAINING_FACTORY_CHANGE = "trainingFactoryChange";

// Action Plan
export const TRAINING_ACTION_PLAN_CREATE_OR_UPDATE =
  "trainingActionPlanCreateOrUpdate";
export const GET_ACTION_PLANS = "getTrainingActionPlans";
export const DELETE_ACTION_PLAN = "deleteActionPlan";

export const PUBLIC_TRAINING_ACTION_PLAN_CREATE_OR_UPDATE =
  "publicTrainingActionPlanCreateOrUpdate";
export const PUBLIC_GET_ACTION_PLANS = "publicGetTrainingActionPlans";
export const PUBLIC_DELETE_ACTION_PLAN = "publicDeleteActionPlan";

export const REQUEST_TRAINING_ACTIONS_REPORT = "requestTrainingActionReport";

export const TRAINING_ACTION_BULK_UPLOAD = "trainingActionBulkUpload";

export const FETCH_TRAINING_SUBGROUPS = "fetchTrainingSubGroups";
export const ADD_OR_EDIT_TRAINING_SUBGROUPS = "addTrainingSubGroups";
export const DELETE_TRAINING_SUBGROUP = "deleteTrainingSubGroup";
export const GET_USER_INVITATIONS = "getUserInvitations";
export const DELETE_USER_INVITATION = "deleteUserInvitation";
export const FORCE_ACTIVATE_TRAINING = "forceActivateTraining";
export const RESEND_INVITATION = "resendInvitation";
export const REGENERATE_CERTIFICATE_IMAGE = "regenerateCertificateImage";
export const GRANT_OR_REVOKE_TRAINERSHIP = "grantTrainership";

export const UPDATE_ADMIN_RATING = "updateAdminRating";
export const TRANSFER_ENROLLMENT_TO_AGREEMENT = "transferEnrollmentToAgreement";

/*


   ____        _     _ _         ____                            _____                 _ _
  |  _ \ _   _| |__ | (_) ___   / ___|___  _   _ _ __ ___  ___  | ____|_ __  _ __ ___ | | |
  | |_) | | | | '_ \| | |/ __| | |   / _ \| | | | '__/ __|/ _ \ |  _| | '_ \| '__/ _ \| | |
  |  __/| |_| | |_) | | | (__  | |__| (_) | |_| | |  \__ \  __/ | |___| | | | | | (_) | | |
  |_|    \__,_|_.__/|_|_|\___|  \____\___/ \__,_|_|  |___/\___| |_____|_| |_|_|  \___/|_|_|


*/
export const RE_ENROLL = "reEnroll";
export const ADD_STATUS = "addStatus";
export const UPDATE_RATING = "updateRating";

/*

   ____                               _
  / ___| _   _ _ __  _ __   ___  _ __| |_
  \___ \| | | | '_ \| '_ \ / _ \| '__| __|
   ___) | |_| | |_) | |_) | (_) | |  | |_
  |____/ \__,_| .__/| .__/ \___/|_|   \__|
              |_|   |_|

*/

export const CREATE_OR_UPDATE_FAQ = "createOrUpdateFaq";
export const DELETE_FAQ = "deleteFaq";
export const GET_FAQ = "getFaq";
export const GET_FAQS = "getFaqs";
export const ORDER_FAQS = "orderFaqs";

/*

   _
  | |    ___   __ _ ___
  | |   / _ \ / _` / __|
  | |__| (_) | (_| \__ \
  |_____\___/ \__, |___/
              |___/

*/

export const GET_LOGS = "getLogs";

/*

   _     _ _ _ _
  | |__ (_) | (_)_ __   __ _
  | '_ \| | | | | '_ \ / _` |
  | |_) | | | | | | | | (_| |
  |_.__/|_|_|_|_|_| |_|\__, |
                       |___/

*/

export const GET_BILLINGS = "getBillings";
export const GET_BILLING = "getBilling";
export const DELETE_BILLING = "deleteBilling";
export const GET_BILLING_FULL_DETAILS = "getBillingFullDetailsDetails";
export const CREATE_OR_UPDATE_BILLINGS = "createOrUpdateBillings";
export const CREATE_OR_UPDATE_BILLING_SERVICES =
  "createOrUpdateBillingServices";
export const DELETE_BILLING_SERVICES = "deleteBillingServices";
export const CREATE_OR_UPDATE_BILLING_PAYMENT = "createOrUpdateBillingPayment";

/*
  ####   ######  ######  #          #    #    #  ######
 #    #  #       #       #          #    ##   #  #
 #    #  #####   #####   #          #    # #  #  #####
 #    #  #       #       #          #    #  # #  #
 #    #  #       #       #          #    #   ##  #
  ####   #       #       ######     #    #    #  ######
*/

export const GENERATE_QUESTION = "generateQuestion";
export const FETCH_REPORTS = "fetchReports";
export const GENERATE_REPORT = "generateReport";
export const CREATE_REPORT = "createReport";
export const UPDATE_REPORT = "updateReport";

export const GET_TRAINING_DATA = "getTrainingData";
export const DOWNLOAD_TRAINING_DATA = "downloadTrainingData";

export const TRAINING_USER_UPDATE = "trainingUserUpdate";
export const TEMPLATE = "template";
export const TEMPLATE_CREATE_OR_UPDATE = "templateCreateUpdate";
export const TEMPLATE_FILE = "templateFile";
export const TEMPLATE_FILE_UPLOAD = "templateFileUpload";
export const TEMPLATE_FILE_DELETE = "templateFileDelete";

export const PUBLIC_TEMPLATE_FILE = "publicTemplateFile";
export const TRAINING_SESSION_CREATE_OR_UPDATE =
  "trainingSessionCreateOrUpdate";
export const TRAINING_SESSION = "trainingSession";
export const TRAINING_SESSION_DETAILS = "trainingSessionDetails";
export const TRAINING_SESSION_DELETE = "trainingSessionDelete";
export const TRAINING_SESSION_FILE_UPLOAD = "trainingSessionFileUpload";
export const TRAINING_SESSION_FILES = "trainingSessionFiles";
export const TRAINING_SESSION_FILE_DELETE = "trainingSessionFilesDelete";

export const DOWNLOAD_TRAINING_SESSION_DATA = "downloadTrainingSessionByData";

export const TRAINING_SESSION_EVENT_CREATE_OR_UPDATE =
  "trainingSessionEventCreateOrUpdate";
export const FETCH_EVENTS = "fetchEvents";
export const DELETE_EVENT = "deleteEvent";

// Agreement
export const GET_AGREEMENTS = "getAgreements";
export const GET_AGREEMENT = "getAgreement";
export const CREATE_OR_UPDATE_AGREEMENT = "createOrUpdateAgreement";
export const DELETE_AGREEMENT = "deleteAgreement";
export const ENROLL_USERS_TO_AGREEMENT = "enrollUsersToAgreement";
export const REMOVE_USERS_FROM_AGREEMENT = "removeUsersFromAgreement";
export const ADD_OR_REMOVE_QUOTA = "addOrRemoveQuota";
export const UPDATE_ENROLLMENT_ACTIVE_STATUS = "updateEnrollmentActiveStatus";

// Visits
export const GET_VISIT_AGREEMENTS = "getVisits";
export const CREATE_OR_UPDATE_VISIT_SCHEDULE = "createOrUpdateVisitSchedule";
export const DELETE_VISIT_SCHEDULE = "deleteVisitSchedule";
export const CREATE_OR_UPDATE_VISIT_ITERATION = "createOrUpdateVisitIteration";
export const DELETE_VISIT_ITERATION = "deleteVisitIteration";

// Access Control
export const FETCH_GROUPS = "fetchGroups";
export const FETCH_GROUP = "fetchGroup";
export const ADD_PERMISSION_TO_GROUP = "addPermissionToGroup";
export const REMOVE_PERMISSION_FROM_GROUP = "removePermissionFromGroup";
export const ADD_USERS_TO_GROUP = "addUsersToGroup";
export const REMOVE_USER_FROM_GROUP = "removeUserFromGroup";
export const CREATE_OR_UPDATE_GROUP = "createOrUpdateGroup";
export const DELETE_GROUP = "deleteGroup";
export const FETCH_USER_PERMISSIONS = "fetchUserPermissions";
export const ADD_PERMISSION_TO_USER = "addPermissionToUser";
export const REMOVE_PERMISSION_FROM_USER = "removePermissionFromUser";
