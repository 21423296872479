import { RouteConfig } from "vue-router";
import { UserRoles } from "../types/common.types";
import { LOGIN_REQUIRED } from "@/utils/user";

const routes: Array<RouteConfig> = [
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/public/SignUp.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/public/LoginPage.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    name: "SetPasswordPage",
    path: "/user/invites/:code",
    component: () => import("../views/public/SetPasswordPage.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    name: "ConfirmForgotPassword",
    path: "/user/password/confirm-forgot-password/:code",
    component: () => import("../views/public/ConfirmForgotPassword.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/public/Faq.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    path: "/certificate-preview/:uuid/",
    name: "certificate-preview",
    component: () => import("../views/public/CertificatePreview.vue"),
    meta: {
      accessLevel: null
    }
  },
  {
    path: "/trainer-certificate-preview/:uuid/",
    name: "trainer-certificate-preview",
    component: () => import("../views/public/TrainerCertificatePreview.vue"),
    meta: {
      accessLevel: null
    }
  },
  // Publicly endpoint end

  {
    path: "/:slug/course-details",
    name: "CourseDetails",
    component: () => import("../views/public/CourseDetails.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },

  {
    path: "/:slug/course-details/present/module/:m_id/content/:c_id",
    name: "present",
    component: () => import("../views/public/PresentLesson.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/:slug/course-details/continue",
    name: "continue",
    component: () => import("../views/public/course/ContinueView.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/:slug/status",
    name: "course-status",
    component: () => import("../views/public/CourseStatus.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/:slug/course-details/summary/:module_id",
    name: "module-summary",
    component: () => import("../views/public/ModuleStatusPage.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/:slug/course-details/retake/:id",
    name: "retake",
    component: () => import("../views/public/course/RetakeExamView.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/:slug/course-details/recap/:id",
    name: "recap",
    component: () => import("../views/public/course/RecapContentView.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/profile/edit",
    name: "edit-profile",
    component: () => import("../views/public/EditProfile.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },


  {
    path: "/profile/action-plans",
    name: "user-action-plans",
    component: () => import("../views/public/UserTrainingActionPlans.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/profile/:slug/status/training-data",
    name: "training-data",
    component: () =>
      import("../views/public/UserCourseTrainingActionPlans.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/public/Profile.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },
  {
    path: "/message",
    name: "message",
    component: () => import("../views/public/Message.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  },

  {
    path: "/",
    name: "publichome",
    component: () => import("../views/public/PublicHome.vue"),
    meta: {
      accessLevel: LOGIN_REQUIRED
    }
  }

  // // TODO: those page are not working
  // {
  //   // TODO: same as Faq
  //   path: "/userguide",
  //   name: "userguide",
  //   component: () => import("../views/public/UserGuide.vue"),
  //   meta: {
  //     accessLevel: null,
  //   },
  // },
  // {
  //   path: "/quiz-attempt",
  //   name: "QuizAttempt",
  //   component: () => import("../views/public/QuizAttempt.vue"),
  //   meta: {
  //     accessLevel: null,
  //   },
  // },
  // {
  //   path: "/wrong-answer",
  //   name: "wrong-answer",
  //   component: () => import("../views/public/WrongAnswer.vue"),
  //   meta: {
  //     accessLevel: null,
  //   },
  // },

  // {
  //   path: "/exam",
  //   name: "courseexam",
  //   component: () => import("../views/public/CourseExam.vue"),
  //   meta: {
  //     accessLevel: null,
  //   },
  // },
  // {
  //   path: "/forum",
  //   name: "forum",
  //   component: () => import("../views/public/Forum.vue"),
  //   meta: {
  //     accessLevel: null,
  //   },
  // },
];

export default routes;
