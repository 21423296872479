import { Method } from "axios";
export enum ContentTypeEnum {
  EXAM = "exam",
  LESSON = "lesson"
}

export const namespaced = (namespace: string, asset: string) =>
  `${namespace}/${asset}`;

export const buildParams = (
  params: { [key: string]: any },
  exclude: string[] = []
): string => {
  const keys = Object.keys(params);
  if (keys.length == 0) return "";
  const parts: string[] = [];
  keys.forEach((k: string) => {
    if (!exclude.includes(k)) {
      if (params[k]) {
        parts.push(`${k}=${params[k]}`);
      }
    }
  });
  if (parts.length) {
    return "?" + parts.join("&");
  } else {
    return "";
  }
};

export const calculateCourseMeta = (data: any) => {
  const coursemeta = {
    totalExam: 0,
    totalLesson: 0,
    countVideo: 0,
    countPDF: 0,
    countImage: 0,
    countText: 0
  };
  function countType(obj: any, lesson_type: any) {
    Object.keys(lesson_type).forEach((type: string) => {
      if (type === "YOUTUBE" || type === "VIDEO")
        obj.countVideo += lesson_type[type];
      else if (type === "PDF") obj.countPDF += lesson_type[type];
      else if (type === "IMG") obj.countImage += lesson_type[type];
      else if (type === "TEXT") obj.countText += lesson_type[type];
    });
  }
  data.modules.forEach((module_obj: any) => {
    const { exam, lesson } = module_obj.contents.reduce(
      (state: any, data: any) => {
        if (data.c_type == "lesson") {
          countType(coursemeta, data.lesson_type);
          state.lesson += 1;
        }
        if (data.c_type == "exam") {
          state.exam += 1;
        }
        return state;
      },
      { exam: 0, lesson: 0 }
    );
    coursemeta.totalExam += exam;
    coursemeta.totalLesson += lesson;
  });
  return coursemeta;
};

export const isVideo = (type: string | null) =>
  type && (type.toLowerCase() === "youtube" || type.toLowerCase() === "video");
export const isVideoYoutube = (type: string | null) =>
  type && type.toLowerCase() === "youtube";
export const isVideoMedia = (type: string | null) =>
  type && type.toLowerCase() === "video";
export const isPDF = (type: string | null) =>
  type && type.toLowerCase() === "pdf";
export const isImage = (type: string | null) =>
  type && type.toLowerCase() === "img";
export const isText = (type: string | null) =>
  type && type.toLowerCase() === "text";
export const downloadFile = (url: string, filename: string) => {
  fetch(url)
    .then((response: any) => response.blob())
    .then((blob: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
};

const imageExtentions = ["gif", "png", "jpe", "jpg", "jpeg", "webp"];

export const isImageUrl = (url: string) => {
  const ext = url?.split(".").pop();
  if (ext && imageExtentions.includes(ext.toLowerCase())) {
    return true;
  }
  return false;
};

export const isImageExtension = (ext: string) => {
  if (ext && imageExtentions.includes(ext.toLowerCase())) {
    return true;
  }
  return false;
};

export const METHODS_MAP: { [key: string]: Method } = {
  list: "get",
  get: "get",
  post: "post",
  patch: "patch",
  delete: "delete"
};
