import Vue from "vue";
import Vuex from "vuex";
import userDataStore from "./modules/user";
import messageDataStore from "./modules/message";
import cmsDataStore from "./modules/cms";
import adminCourseData from "./modules/admin/courses";
import mediaBrowserData from "./modules/media";
import adminOrgModule from "./modules/admin/organizations";
import publicCourseData from "./modules/public/courses";
import publicTrainingData from "./modules/public/training";
import adminEnrollmentsModule from "./modules/admin/enrollments";
import adminTrainingModule from "./modules/admin/training";
import publicSupportModule from "./modules/public/support";
import adminSupportModule from "./modules/admin/support";
import adminBillingModule from "./modules/admin/billing";
import AgreementStore from "./modules/admin/agreement";
import AccessControlStore from "./modules/admin/accessControl";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: "1.0.0"
  },
  mutations: {},
  actions: {},
  modules: {
    user: userDataStore,
    message: messageDataStore,
    cms: cmsDataStore,
    media: mediaBrowserData,
    admin: {
      namespaced: true,
      modules: {
        courses: adminCourseData,
        organizations: adminOrgModule,
        enrollments: adminEnrollmentsModule,
        support: adminSupportModule,
        billing: adminBillingModule,
        training: adminTrainingModule,
        agreement: AgreementStore,
        accessControl: AccessControlStore
      }
    },

    public: {
      namespaced: true,
      modules: {
        courses: publicCourseData,
        support: publicSupportModule,
        training: publicTrainingData
        // forums: forumCategory,
      }
    }
  }
});
