import axios, { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { MediaBrowserDataState, RootState } from "../../types/store.types";
import { MediaBrowserFilter } from "../../types/additionalApi.types";
import type { PaginatedNodeList, Node } from "../../types/api.types";

import { HOST, MEDIA_API_URL } from "../endpoints";

import {
  GET_MEDIA_IMAGE,
  GET_MEDIAS,
  UPLOAD_MEDIA,
  DELETE_MEDIA
} from "../action.names";
import { NS_USER } from "../namespace.names";
import { AUTH_HEADER } from "../getter.names";
import { GET_MEDIA_BY_SLUG } from "../action.names";

import { namespaced, buildParams } from "../utils";

const state: MediaBrowserDataState = {};

const getters: GetterTree<MediaBrowserDataState, RootState> = {};

const actions: ActionTree<MediaBrowserDataState, RootState> = {
  async [GET_MEDIA_IMAGE]({ rootGetters }, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(HOST + url, {
          responseType: "arraybuffer",
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((resp: any) => {
          //   const reader = new window.FileReader();

          //   reader.readAsDataURL(resp.data);
          //   reader.onload = function() {

          //     // console.log("success", imageDataUrl);

          //   };
          const imageDataUrl = Buffer.from(resp.data, "binary").toString(
            "base64"
          );
          resolve("data:image/png;base64," + imageDataUrl);
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
  },
  async [GET_MEDIAS](
    { rootGetters },
    params: MediaBrowserFilter
  ): Promise<PaginatedNodeList | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MEDIA_API_URL}/${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data as PaginatedNodeList);
        })
        .catch((e: AxiosError) => {
          resolve(e);
        });
    });
  },
  async [GET_MEDIA_BY_SLUG](
    { rootGetters },
    slug: string
  ): Promise<Node | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MEDIA_API_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data as Node);
        })
        .catch((e: AxiosError) => {
          resolve(e);
        });
    });
  },
  async [DELETE_MEDIA]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${MEDIA_API_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((resp: any) => {
          resolve(resp);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [UPLOAD_MEDIA]({ rootGetters }, data: any): Promise<Node | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MEDIA_API_URL}/`, data.payload, {
          onUploadProgress: progressEvent => {
            if (progressEvent.total) {
              data.onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          },
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data as Node);
        })
        .catch((e: AxiosError) => {
          resolve(e);
        });
    });
  }
};

const mutations: MutationTree<MediaBrowserDataState> = {};

const mediaBrowserData: Module<MediaBrowserDataState, RootState> = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};

export default mediaBrowserData;
