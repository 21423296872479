import { HAS_PERM, IS_SUPERUSER } from "@/store/getter.names";
import { NS_USER } from "@/store/namespace.names";
import { namespaced } from "@/store/utils";

export enum UserRoleChoices {
  ADMIN = 100,
  INSTRUCTOR = 90,
  ASSESSOR = 50,
  CLIENT = 20,
  BASIC = 0
}

export const LOGIN_REQUIRED = "loginRequired";

export const hasPerm = (user: any, perm: string) => {
  if (user.is_superuser) return true;
  return user.permissions[perm] || false;
};

export const hasPerms = (user: any, perms: string[]) => {
  if (user.is_superuser) return true;
  return perms.every((perm) => hasPerm(user, perm));
};

export const hasAnyPerm = (user: any, perms: string[]) => {
  if (user.is_superuser) return true;
  return perms.some((perm) => hasPerm(user, perm));
};

export const getPermissions = (store: any, perms: any) => {
  const permissions: any = {};
  const getter = store.getters[namespaced(NS_USER, HAS_PERM)];
  const is_superuser = store.getters(namespaced(NS_USER, IS_SUPERUSER));
  Object.keys(perms).forEach((key) => {
    permissions[key] = is_superuser || getter(perms[key]);
  });
  return permissions;
};
