<template>
  <div class="subNav-wrap">
    <!-- -->
    <div class="subNav" :class="{ hideNav: !isMenu }">
      <b-navbar class="nav-fullwidth">
        <b-navbar-nav>
          <b-nav-item v-if="permissions.courseView">
            <router-link :to="{ name: 'admin-courses' }" tag="div">
              <img
                src="../../../assets/icons/dashboard/courses.svg"
                class="s-icon"
                alt="courses"
              />
              Course
            </router-link>
          </b-nav-item>
          <!-- Dashboard Status -->
          <b-nav-item-dropdown
            class="dropdown-btn"
            v-if="permissions.status.masterReports"
          >
            <template #button-content>
              <img
                src="../../../assets/icons/dashboard/organizations.svg"
                class="s-icon"
                alt="status"
              />
              <em class="drop-text">Status</em>
            </template>
            <b-dropdown-item
              v-if="permissions.status.masterReports"
              :to="{ name: 'admin-master-reports' }"
            >
              Master Reports</b-dropdown-item
            >

            <b-dropdown-item
              :to="{ name: 'admin-access-log' }"
              v-if="permissions.status.accessLog"
            >
              Access Log</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <!-- dashboar organizations -->
          <b-nav-item v-if="permissions.agreement">
            <router-link :to="{ name: 'admin-agreements' }" tag="div">
              <img
                src="../../../assets/icons/dashboard/organizations.svg"
                class="s-icon"
                alt="Agreements"
              />
              Agreements
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="permissions.organization || isFocal">
            <router-link :to="{ name: 'admin-organization' }" tag="div">
              <img
                src="../../../assets/icons/dashboard/organizations.svg"
                class="s-icon"
                alt="Organizations"
              />
              Organizations
            </router-link>
          </b-nav-item>
          <!-- dashboard training -->
          <!-- dashboard  Action Plans -->
          <b-nav-item v-if="permissions.actionPlans">
            <router-link :to="{ name: 'admin-action-plans' }" tag="div">
              <img
                src="../../../assets/icons/dashboard/access log.svg"
                class="s-icon"
                alt="Action Plans"
              />
              Action Plans
            </router-link>
          </b-nav-item>
          <!-- dashboard users -->
          <b-nav-item-dropdown
            class="dropdown-btn"
            v-if="
              permissions.user.users ||
              permissions.user.invitations ||
              permissions.user.groups
            "
          >
            <template #button-content>
              <img
                src="../../../assets/icons/dashboard/users.svg"
                class="s-icon"
                alt="Training"
              />
              <em class="drop-text">Users</em>
            </template>
            <b-dropdown-item v-if="permissions.user.users" :to="{ name: 'admin-users' }">
              Users</b-dropdown-item
            >
            <b-dropdown-item
              v-if="permissions.user.groups"
              :to="{ name: 'admin-groups' }"
            >
              Groups</b-dropdown-item
            >
            <b-dropdown-item
              v-if="permissions.user.invitations"
              :to="{ name: 'admin-invitations' }"
            >
              Invitations</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <!-- dashboard Enrollments -->
          <!-- dashboard access -->
          <b-nav-item-dropdown
            class="dropdown-btn"
            v-if="
              permissions.visit.visitsView ||
              permissions.visit.manageOwnVisit ||
              permissions.visit.visitReportExport
            "
          >
            <template #button-content>
              <img src="../../../assets/icons/dashboard/training.svg" class="s-icon" />
              <em class="drop-text">Visits</em>
            </template>
            <b-dropdown-item
              v-if="permissions.visit.visitStats"
              :to="{ name: 'admin-visits-overview' }"
            >
              Overview
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permissions.visit.visitsView || permissions.visit.manageOwnVisit"
              :to="{ name: 'admin-visits-all' }"
            >
              All Visits
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'admin-visits-factories' }"
              v-if="permissions.visit.visitsView"
            >
              Visits by Agreements
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'admin-visit-exports' }"
              v-if="permissions.visit.visitReportExport"
            >
              Visit Reports
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- dashboard billing -->
          <b-nav-item v-if="permissions.billing">
            <router-link :to="{ name: 'admin-billing' }" tag="div">
              <img
                src="../../../assets/icons/dashboard/support.svg"
                class="s-icon"
                alt=" Access Log"
              />
              Billing
            </router-link>
          </b-nav-item>

          <!-- Navbar dropdowns -->
          <b-nav-item-dropdown class="dropdown-btn" v-if="permissions.faq">
            <template #button-content>
              <img
                src="../../../assets/icons/dashboard/training.svg"
                class="s-icon"
                alt=" Access Log"
              />
              <em class="drop-text">Support</em>
            </template>
            <b-dropdown-item href="" v-if="permissions.faq">
              <router-link :to="{ name: 'admin-support-faq' }" tag="div">
                FAQS
              </router-link></b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script lang="ts">
import { GET_PERMISSIONS_MAP, HAS_PERM, IS_FOCAL, PROFILE } from "@/store/getter.names";
import { UserModule } from "@/store/namespace.names";
import type { PublicProfile } from "@/types/api.types";
import {
  FACTORY_VISIT_STATS_PERM,
  MANAGE_OWN_FACTORY_VISITS_PERM,
  VIEW_ADMIN_LOG_ENTRY_PERM,
  VIEW_AGREEMENT_PERM,
  VIEW_COURSE_PERM,
  VIEW_FACTORY_VISIT_PERM,
  VIEW_FAQ_PERM,
  VIEW_GROUP_PERM,
  VIEW_MASTER_REPORT_PERM,
  VIEW_ORGANIZATION_BILLING_PERM,
  VIEW_ORGANIZATION_PERM,
  VIEW_PROFILE_PERM,
  VIEW_TRAINING_ACTION_PERM,
  VIEW_USER_INVITATION_PERM,
  VIEW_VISIT_REPORTS_EXPORT_PERM,
} from "@/utils/permission.names";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SubNavbar",
  components: {},
})
export default class SubNavbar extends Vue {
  @UserModule.Getter(PROFILE) profile!: PublicProfile;
  dropDownList = false;
  trainingDropDown = false;
  @UserModule.Getter(HAS_PERM) [HAS_PERM]: any;
  @UserModule.Getter(IS_FOCAL) isFocal!: boolean;

  @UserModule.Getter(GET_PERMISSIONS_MAP) [GET_PERMISSIONS_MAP]: any;

  @Prop() isMenu!: boolean;

  get permissions() {
    return {
      ...this[GET_PERMISSIONS_MAP]({
        courseView: VIEW_COURSE_PERM,
        agreement: VIEW_AGREEMENT_PERM,
        organization: VIEW_ORGANIZATION_PERM,
        actionPlans: VIEW_TRAINING_ACTION_PERM,
      }),
      status: this[GET_PERMISSIONS_MAP]({
        masterReports: VIEW_MASTER_REPORT_PERM,
        accessLog: VIEW_ADMIN_LOG_ENTRY_PERM,
      }),
      user: this[GET_PERMISSIONS_MAP]({
        users: VIEW_PROFILE_PERM,
        invitations: VIEW_USER_INVITATION_PERM,
        groups: VIEW_GROUP_PERM,
      }),
      visit: this[GET_PERMISSIONS_MAP]({
        manageOwnVisit: MANAGE_OWN_FACTORY_VISITS_PERM,
        visitsView: VIEW_FACTORY_VISIT_PERM,
        visitStats: FACTORY_VISIT_STATS_PERM,
        visitReportExport: VIEW_VISIT_REPORTS_EXPORT_PERM,
      }),
      faq: this[HAS_PERM](VIEW_FAQ_PERM),
      billing: this[HAS_PERM](VIEW_ORGANIZATION_BILLING_PERM),
    };
  }
}
</script>

<style lang="scss" scoped>
.subNav-wrap {
  display: flex;
  justify-content: center;

  .subNav {
    margin-top: -3.5vh;
    display: flex;
    justify-content: center;
    width: 80%;

    .nav-fullwidth {
      width: 100%;
      height: 70px;
      background-color: #fff;
      list-style-type: none;
      box-shadow: 0 0 5px 0 #00000040;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      z-index: 1;
      padding: 0;

      .navbar-nav {
        flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: center;

        .nav-item {
          margin-right: 20px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .nav-link {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            padding: 0px 5px;
            transition: all 0.3s ease-in-out;

            div {
              position: relative;

              &::after {
                content: "";
                border-bottom: 0px solid #ec008c;
                width: 100%;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }

            &:hover {
              div {
                &::after {
                  border-bottom: 3px solid #ec008c;
                }
              }
            }

            .router-link-active {
              position: relative;

              &::after {
                content: "";
                border-bottom: 3px solid #ec008c;
                width: 100%;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      position: fixed;
      width: 100%;
      left: 0;
      top: 70px;
      height: 100vh;
      z-index: 991;
      margin-top: 10px;

      .nav-fullwidth {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 70px);
        background: #ec008c;

        .navbar-nav {
          display: flex;
          flex-direction: column;

          .nav-item {
            height: unset;
            padding: 15px;
          }
        }
      }

      .dropdown-btn {
        ::after {
          color: #fff;
        }
      }
    }

    @media (max-width: 992px) {
      margin-top: 0;
    }
  }

  @media (max-width: 1024px) {
    .hideNav {
      display: none;
    }
  }
}

.dropdown-navbar {
  position: absolute;
  z-index: 10;
  background: #fff;
  top: 50px;
  width: 150px;
  left: -20px;
  list-style: none;
  padding: 0;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);
  -moz-box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);
  z-index: 3;

  a {
    color: #777777;

    @media (max-width: 1024px) {
      color: #fff;
    }
  }

  li {
    border-bottom: 1px solid rgba($color: #e6e6e6, $alpha: 1);
    padding: 1rem 2rem;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background: rgba($color: #e6e6e6, $alpha: 1);
    }

    a {
      display: block;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }

      @include ParagraphText;
      color: #777777;
    }
  }
}

.dropDownfade-enter-active,
.dropDownfade-leave-active {
  transition: opacity 0.2s;
}

.dropDownfade-enter,
.dropDownfade-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.nav-item-link {
  position: relative;
}

.triangle {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 30px solid white;
  top: -14px;
  right: 55px;
  z-index: -1;
}

.s-icon {
  width: 18px;
  margin-right: 5px;

  @media (min-width: 1025px) and (max-width: 1600px) {
    width: 14px;
  }

  @media (max-width: 1024px) {
    filter: brightness(0) saturate(100%) invert(100%) sepia(31%) saturate(2%)
      hue-rotate(334deg) brightness(114%) contrast(100%);
  }
}

.nav-link {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(17px + 0.05vw);
    color: #7c7c7c;
    height: 100%;

    @media (min-width: 1025px) and (max-width: 1600px) {
      font-size: calc(14px + 0.05vw);
    }

    @media (max-width: 1024px) {
      color: #fff;
    }
  }
}

.navbar-nav {
  .nav-item {
    margin-right: 20px;

    @media (min-width: 1025px) and (max-width: 1600px) {
      margin-right: 10px !important;
    }
  }
}

.dropdown-toggle {
  em {
    font-style: normal;
    font-size: calc(18px + 0.05vw);
    color: #7c7c7c;

    @media (min-width: 1025px) and (max-width: 1600px) {
      font-size: calc(14px + 0.05vw);
    }

    @media (max-width: 1024px) {
      color: #fff;
    }
  }
}
</style>
