<template>
  <div>
    <div class="main-nav">
      <b-navbar
        toggleable="lg"
        class="navbar-container"
        :class="{ publicNavContainer: !isDashBoard }"
      >
        <div class="navbar-wrap" :class="{ publicNav: !isDashBoard }">
          <b-navbar-brand class="navbar-brand">
            <router-link to="/"
              ><img src="@/assets/icons/dashboard/logo.svg"
            /></router-link>
          </b-navbar-brand>

          <div class="nav-items">
            <router-link
              @mouseover.native="dropDownList = true"
              @mouseleave.native="dropDownList = false"
              to="#"
              class="nav-item-link"
            >
              <b-avatar
                v-if="profile && profile.user.profile_image_url"
                :src="HOST + profile.user.profile_image_url"
                size="35"
              ></b-avatar>
              <b-avatar
                v-else
                :src="require('../assets/user-placeholder.svg')"
                size="35"
              ></b-avatar>

              <transition name="dropDownfade" :key="'dropdownnav4'">
                <ul
                  class="dropdown-navbar"
                  v-if="dropDownList"
                  @click="dropDownList = false"
                >
                  <li class="edit-pro-section">
                    <b-avatar
                      v-if="profile && profile.user.profile_image_url"
                      class="edit-pro-avatar"
                      :src="HOST + profile.user.profile_image_url"
                      size="55"
                    ></b-avatar>
                    <b-avatar
                      v-else
                      class="edit-pro-avatar"
                      :src="require('../assets/user-placeholder.svg')"
                      size="55"
                    ></b-avatar>

                    <div>
                      <div class="name">{{ profile.user.full_name }}</div>
                      <b-button
                        class="nav-pro-edit-btn"
                        variant="primary"
                        :to="{ name: 'edit-profile' }"
                        >Edit Profile</b-button
                      >
                    </div>
                  </li>
                  <router-link :to="{ name: 'profile' }">
                    <li>Profile</li>
                  </router-link>
                  <router-link v-if="dashboardAccess" :to="dashboardAccess">
                    <li>Dashboard</li>
                  </router-link>
                  <router-link :to="{ name: 'faq' }">
                    <li>FAQS</li>
                  </router-link>
                  <router-link :to="{ name: 'message' }">
                    <li>Message</li>
                  </router-link>
                  <router-link to="">
                    <li @click="user_logout">Logout</li>
                  </router-link>
                  <div class="triangle"></div>
                </ul>
              </transition>
            </router-link>

            <router-link
              to=""
              class="nav-item-link"
              @mouseover.native="dropDownNotification = true"
              @mouseleave.native="dropDownAction"
            >
              <div class="notification" v-if="notifications">
                <b-img :src="require('@/assets/notification-icon.svg')"></b-img>
                <div class="notification-number">
                  {{ total_unread_notification }}
                </div>
              </div>

              <transition
                name="dropDownfade"
                :key="'dropdownnav5'"
                v-if="notifications.results"
              >
                <div v-if="notifications.results.length > 0">
                  <ul
                    class="dropdown-navbar dropdown-notification-nav"
                    v-if="dropDownNotification"
                  >
                    <div
                      class="notif-scroll"
                      :class="{ scrollFull: notifications.results.length > 10 }"
                    >
                      <li
                        class="notification-items-list"
                        v-for="notif in notifications.results"
                        :key="'notification' + notif.id"
                      >
                        <b-avatar
                          v-if="notif.actor === null"
                          class="edit-pro-avatar"
                          :src="require('../assets/user-placeholder.svg')"
                          size="45"
                        ></b-avatar>

                        <b-avatar
                          v-else-if="
                            notif.actor !== null && !notif.actor.profile_image_url
                          "
                          class="edit-pro-avatar"
                          :src="require('../assets/user-placeholder.svg')"
                          size="45"
                        ></b-avatar>

                        <img
                          v-else
                          class="edit-pro-avatar"
                          :src="getRestrictedMedia(notif.actor.profile_image_url)"
                        />

                        <div class="notification-item">
                          <p class="n-text">
                            {{ notif.description }}
                          </p>
                          <p class="time">{{ notif.timesince }} ago</p>
                        </div>
                      </li>
                    </div>
                    <div class="all-notification">
                      <button
                        class="CustomBtn all-noti-btn"
                        @click="showAllNotifications"
                      >
                        <img
                          class="down-arrow bounce"
                          :src="require(`@/assets/icons/down-arrow.png`)"
                          alt=""
                        />
                      </button>
                    </div>
                    <div class="triangle"></div>
                  </ul>
                </div>
                <div v-else>
                  <ul
                    class="dropdown-navbar dropdown-notification-nav"
                    v-if="dropDownNotification"
                  >
                    <li class="notification-items-list no-notification">
                      No New Notification
                    </li>
                    <div class="all-notification">
                      <button
                        class="CustomBtn all-noti-btn"
                        @click="showAllNotifications"
                      >
                        <img
                          class="down-arrow bounce"
                          :src="require(`@/assets/icons/down-arrow.png`)"
                          alt=""
                        />
                      </button>
                    </div>
                    <div class="triangle"></div>
                  </ul>
                </div>
              </transition>
            </router-link>

            <a
              href="javascript:void(0);"
              v-show="isDashBoard"
              class="menu-button"
              @click="toggleMenu()"
              :class="{ active: isMenu }"
            >
              <img
                class="menu-icon close-icon"
                :src="require(`@/assets/icons/close.png`)"
              />
              <img
                class="menu-icon hamburger"
                :src="require(`@/assets/icons/menu.png`)"
              />
            </a>
          </div>
        </div>
      </b-navbar>
    </div>
    <!-- menu start  -->
    <SubNavbar v-if="dashboardAccess" v-show="isDashBoard" :isMenu="isMenu" />
    <!-- menu end  -->
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Component } from "vue-property-decorator";
import {
  GET_NOTIFICATIONS,
  LOGOUT,
  READ_NOTIFICATIONS,
  SEARCH_COURSES,
} from "@/store/action.names";
import { HOST } from "@/store/endpoints";
import {
  PROFILE,
  NOTIFICATION,
  IS_FOCAL,
  DASHBOARD_ACCESS,
  HAS_PERM,
} from "@/store/getter.names";
import { SET_COURSE_SEARCH_RESULTS } from "@/store/mutation.names";
import { PublicModule, UserModule } from "@/store/namespace.names";

import { getRestrictedMedia } from "../utils/media";
import SubNavbar from "./dashboard/SubNavbar/SubNavbar.vue";
import type { ActionMethod, MutationMethod } from "vuex";
import type { Api, PublicProfile } from "@/types/api.types";
import type { UserNotification } from "@/types/store.types";

@Component({
  name: "DashboardNavbar",
  components: {
    SubNavbar,
  },
})
export default class DashboardNavbar extends Vue {
  @UserModule.Action(LOGOUT) logout!: ActionMethod;
  @PublicModule.Action(SEARCH_COURSES) searchCourses!: ActionMethod;
  @UserModule.Action(GET_NOTIFICATIONS) fetchNotification!: ActionMethod;
  @UserModule.Action(READ_NOTIFICATIONS) readAllNotifications!: ActionMethod;
  @PublicModule.Mutation(SET_COURSE_SEARCH_RESULTS)
  setCourseSearchResults!: MutationMethod;
  @UserModule.Getter(PROFILE) profile!: PublicProfile;
  @UserModule.Getter(NOTIFICATION) notifications!: UserNotification;
  @UserModule.Getter(DASHBOARD_ACCESS) [DASHBOARD_ACCESS]!: any;

  @UserModule.Getter(HAS_PERM) [HAS_PERM]: any;
  @UserModule.Getter(IS_FOCAL) isFocal!: boolean;

  getRestrictedMedia = getRestrictedMedia;

  isMenu = false;
  showDesktopview = false;
  mobileNav = true;
  dropDownList = false;
  dropDownNotification = false;
  showSearchBar = false;
  HOST = HOST;
  N_LIMIT = 10;
  total_unread_notification = 0;

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }
  @Watch("$route", { deep: true })
  route() {
    this.isMenu = false;
  }

  created() {
    this.handleView();
    this.fetchNotifications({ limit: this.N_LIMIT });
  }
  user_logout() {
    this.logout().then(() => {
      this.$router.push("/login");
    });
  }
  async fetchNotifications(params: Api.PublicNotificationsList.RequestQuery = {}) {
    try {
      const data = await this.fetchNotification({
        ...params,
        limit: this.N_LIMIT,
      });
      this.total_unread_notification = data.total_unread;
    } catch (e) {
      console.log(e);
    }
  }

  handleView() {
    this.mobileNav = window.innerWidth <= 993;
    window.addEventListener("resize", this.handleView);
  }

  async logoutUser() {
    this.logout().then(() => {
      this.$router.push({
        name: "login",
      });
    });
  }

  showAllNotifications() {
    const next = this.notifications?.next;
    if (next != null) {
      const offset = next.match(/offset=(\d*)/);
      const params = { limit: this.N_LIMIT, offset: offset ? offset[1] : 0 };
      this.fetchNotification(params);
    }
  }

  dropDownAction() {
    this.dropDownNotification = false;
    if (this.notifications.is_make_unread == false) {
      this.total_unread_notification = 0;
      this.readAllNotifications();
    }
  }

  get isDashBoard() {
    const parts = this.$route.fullPath.split("/");
    if (parts.length < 2) return false;
    return parts[1] == "dashboard";
  }
}
</script>

<style lang="scss" scoped>
.main-nav {
  background-color: $title-color;

  .navbar-container {
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    height: 14vh;
  }

  .publicNavContainer {
    height: auto !important;
  }

  .navbar-wrap {
    width: 80%;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .publicNav {
    margin-bottom: 0 !important;
    display: flex !important;
  }

  .edit-pro-section {
    display: flex;
    align-items: center;

    div {
      .btn {
        font-size: calc(14px + 0.05vw);
        text-transform: capitalize;
      }
    }
  }

  .navbar-brand a {
    font-family: $title-font;
    text-decoration: none;
    color: white;

    img {
      @media (max-width: 993px) {
        width: 90px;
      }
    }
  }

  .navbar-brand a:hover {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 1024.9px) {
    .navbar-container {
      height: auto;
    }

    .navbar-wrap {
      margin-bottom: 0;

      .navbar-toggler {
        float: right;
        border: none;
        padding-right: 0px;
      }

      .navbar-toggler:focus {
        outline: none;
      }
    }

    .mobile-avatar-hide {
      display: none;
    }
  }
}

::v-deep .edit-pro-avatar {
  margin-right: 12px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.dropdown-navbar {
  position: absolute;
  z-index: 10;
  background: #fff;
  top: 50px;
  width: 300px;
  left: -200px;
  list-style: none;
  padding: 0;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);
  -moz-box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(28, 67, 81, 0.2);

  a {
    color: #777777;
    text-decoration: none;
  }

  li {
    border-bottom: 1px solid rgba($color: #e6e6e6, $alpha: 1);
    padding: 1rem 2rem;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background: rgba($color: #e6e6e6, $alpha: 1);
    }

    a {
      display: block;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }

      @include ParagraphText;
      color: #777777;
    }
  }
}

.dropdown-notification-nav {
  width: 500px;
  left: -400px;

  .notif-scroll {
    max-height: 333px;
    overflow-y: scroll;
  }

  .scrollFull {
    max-height: 700px;
  }
}

.notification-items-list {
  display: flex;
  padding: 10px 12px !important;

  .n-text {
    color: #717073;
    font-size: calc(14px + 0.05vw);
    color: #777777;
    margin-bottom: 7px;
  }

  b {
    color: #333333;
  }

  .time {
    font-family: $secondary-font;
    color: #ec008c;
    font-size: calc(12px + 0.05vw);
    margin-bottom: 0;
  }

  .notification-wrap {
    display: flex;
  }
}

.no-notification {
  display: flex;
  justify-content: center;
  color: #333333;
}

.all-noti-btn {
  background-color: $button-bg-1;
  color: $title-color;
  height: 40px;
  width: 100%;
}

.notification-items-list ::v-deep .b-avatar-img {
  img {
    width: auto;
  }
}

.triangle {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 30px solid white;
  top: -14px;
  right: 55px;
  z-index: -1;
}

@media (max-width: 992px) {
  .triangle {
    top: -20px;
    right: 60px;
  }
}

.mobile-search-input {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -46px;
  z-index: 6;
}

.mobile-search-input ::v-deep .form-control {
  border-radius: 0 !important;
  height: calc(2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: none !important;
}

.nav-items {
  display: flex;
  align-items: center;
}

.nav-item-link {
  margin-left: 25px;
  position: relative;

  @media (max-width: 993px) {
    margin-left: 15px;
  }
}

.notification {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 993px) {
    height: 30px;
    width: 30px;
  }

  .notification-number {
    position: absolute;
    right: -12px;
    top: -14px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid $title-color;
    background: #13b5ea;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    @media (max-width: 993px) {
      height: 25px;
      width: 25px;
      font-size: 12px;
    }
  }
}

.name {
  margin-bottom: 10px;
  color: #333333;
  @include ParagraphText;
  text-transform: capitalize;
}

// .burger-menu {
//   border: 1px solid rgba($color: #fff, $alpha: 0.4);
//   padding: 10px;
//   cursor: pointer;
//   .line {
//     width: 30px;
//     height: 2px;
//     background: #fff;
//     margin-bottom: 7px;
//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
// }

.bounce {
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-6px);
  }

  // 80% {
  //   transform: translateY(0px);
  // }
}

.down-arrow {
  width: 20px;
  height: 20px;
}

.dropDownfade-enter-active,
.dropDownfade-leave-active {
  transition: opacity 0.2s;
}

.dropDownfade-enter,
.dropDownfade-leave-active {
  opacity: 0;
}

.searchButton {
  background-color: $bg-lightBlue;
  border-radius: 100%;
  font-size: 10px;
  height: 35px;
  width: 35px;

  @media (max-width: 993px) {
    height: 30px;
    width: 30px;
  }
}

.searchButton:hover {
  color: white;
  background-color: $bg-lightBlue-hover;
}

@media (max-width: 1024px) {
  .subnavbarMobile,
  .desktop-notif,
  .searchbar-mobile {
    display: none;
  }
}

// transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.menu-icon {
  width: 25px;
  height: 25px;
}

.close-icon {
  width: 25px;
  height: 25px;
}

.mobile-nav-toggle {
  padding-top: 0.25rem;
  display: flex;
  align-items: center;
  float: right;
}

::v-deep .avatar-img img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;

  @media (max-width: 993px) {
    width: 30px;
    height: 30px;
  }
}

::v-deep .nav-pro-edit-btn {
  color: #fff !important;
}

@media (min-width: 1025px) {
  ::v-deep .mobile-nav-toggle {
    display: none;
  }

  .subnavbar {
    display: none;
  }
}

@media (max-width: 1024.9px) {
  .hide-mobile {
    display: none;
  }

  .navbar-collapse {
    width: 100%;
  }

  ::v-deep .navbar-nav {
    width: 100%;
  }
}

.notif-scroll::-webkit-scrollbar {
  width: 3px;
}

.notif-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.notif-scroll::-webkit-scrollbar-thumb {
  background-color: #ec008c;
  outline: 1px solid #ec008c;
}

.menu-button {
  margin-left: 20px;

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.active {
  .hamburger {
    display: none;
  }

  .close-icon {
    display: block;
  }
}

.close-icon {
  display: none;
}

a.nav-item-link.router-link-exact-active.router-link-active {
  border-bottom: 0px solid #fff;
}
</style>
