<template>
  <div id="app">
    <DashboardNavbar v-if="isNavbarVisible" />
    <div class="main">
      <router-view />
    </div>
    <Footer
      v-if="isFooterVisible && settings.footer"
      :footer="settings.footer"
      :flatMenus="settings.menus.flat_menus"
      :socialMedia="settings.social"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { FETCH_SETTINGS } from "./store/action.names";
import { SETTINGS } from "./store/getter.names";

import { CmsModule } from "@/store/namespace.names";

import DashboardNavbar from "@/components/DashboardNavbar.vue";
import Footer from "@/components/public/Footer.vue";
import type { ActionMethod } from "vuex";
import { Settings } from "./types/cms.types";

@Component({
  components: {
    DashboardNavbar,
    Footer
  }
})
export default class App extends Vue {
  @CmsModule.Action(FETCH_SETTINGS) fetchSettings!: ActionMethod;
  @CmsModule.Getter(SETTINGS) settings!: Settings;

  created() {
    this.fetchSettings();
  }
  get isNavbarVisible() {
    if (
      this.$route?.name &&
      [
        "login",
        "signup",
        "SetPasswordPage",
        "404",
        "ConfirmForgotPassword"
      ].includes(this.$route.name)
    ) {
      return false;
    }
    return true;
  }
  get isFooterVisible() {
    if (
      this.$route?.name &&
      ["login", "signup", "404", "ConfirmForgotPassword"].includes(
        this.$route.name
      )
    ) {
      return false;
    }
    const parts = this.$route.fullPath.split("/");
    return parts[1] != "dashboard";
  }
}
</script>

<style lang="scss">
@import "./assets/css/_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  background-color: $bg-main;
  font-family: $primary-font-family;
  color: $text-color;
}

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
}

.main {
  flex: 1 0 auto;
}

.dashboard-container {
  width: 70%;
  @media (max-width: 1366px) {
    width: 85%;
  }
  @media (max-width: 993px) {
    width: 85%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}

.dashboard-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-topbar-title {
  font-family: $title-font;
  @include MainTitle;
  margin: 1.5rem 0;
}
.dashboard-topbar-path {
  font-family: $primary-font-family;
  font-size: $font-sm;
  .dashboard-last-path {
    color: $text-color-2;
  }
}

.dashboard-body {
  width: 100%;
  background-color: $bg-white;
  padding: 20px;
  border: 0.25px solid $border-color;
  border-radius: 5px;
  margin-bottom: 3rem;
}
.dashboard-body-header {
  padding-bottom: 1.25rem;
  border-bottom: 2px solid $border-color;
}
.dashboard-body-inner {
  width: 100%;
  margin-top: 1rem;
}

.error-message {
  font-size: 12px;
  color: red;
}
</style>
