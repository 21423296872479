import { KeyObj } from "@/types/common.types";
import {
  FETCH_FACTORY_VISITS,
  ADD_FACTORY_VISIT,
  ADD_FACTORY_VISIT_FILE,
  FETCH_FACTORY_VISIT,
  DELETE_FACTORY_VISIT_FILE,
  DELETE_FACTORY_VISIT,
  CREATE_OR_UPDATE_VISIT_SCHEDULE,
  FETCH_FACTORY_VISIT_SCHEDULES,
  DELETE_VISIT_SCHEDULE,
  CREATE_OR_UPDATE_VISIT_ITERATION,
  DELETE_VISIT_ITERATION,
  FETCH_FACTORY_VISIT_STATS,
  EXPORT_FACTORY_VISITS,
  FETCH_FACTORY_VISITS_EXPORTS,
  FETCH_ORG_STATS,
  DELETE_MASTER_REPORT
} from "../../action.names";
import axios, { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { AdminOrganizationState, RootState } from "@/types/store.types";
import {
  PaginatedOrganizationList,
  PaginatedFactoryListList,
  PaginatedContactPersonList,
  ContactPerson,
  Api
} from "@/types/api.types";

import {
  ADMIN_ALL_ORGANIZATION_REPORT,
  ADMIN_API,
  ADMIN_CONTACT_PERSON_URL,
  ADMIN_FACTORIES_URL,
  ADMIN_FACTORY_VISITS_URL,
  ADMIN_FACTORY_VISIT_STATS_URL,
  ADMIN_ORGANIZATIONS_URL,
  ADMIN_ORGANIZATION_REPORT,
  ADMIN_VISIT_REPORTS_EXPORTS
} from "@/store/endpoints";

import { AUTH_HEADER } from "@/store/getter.names";
import { NS_USER } from "@/store/namespace.names";
import {
  GET_ORGANIZATION,
  GET_FACTORIES,
  GET_FACTORY,
  GET_CONTACT_PERSONS,
  CREATE_OR_UPDATE_CONTACT_PERSON,
  GET_CONTACT_PERSON,
  ATTACH_CONTACT_PERSON,
  DETACH_CONTACT_PERSON,
  DELETE_ORGANIZATION,
  DELETE_FACTORY,
  GET_ORGANIZATIONS,
  CREATE_OR_UPDATE_ORGANIZATION,
  CREATE_OR_UPDATE_FACTORY,
  FACTORY_BULK_UPLOAD,
  GENERATE_QUESTION,
  GENERATE_REPORT,
  GENERATE_FACTORY_REPORT,
  CREATE_REPORT,
  FETCH_REPORTS,
  UPDATE_REPORT,
  ADD_OR_REMOVE_FACTORY_COURSE,
  GET_FACTORY_USERS,
  FETCH_MASTER_PLANS,
  MASTER_PLAN_CREATE_OR_UPDATE,
  GENERATE_ORGANIZATION_REPORT,
  GENERATE_ALL_ORGANIZATION_REPORT,
  FETCH_MASTER_REPORTS,
  ADD_OR_REMOVE_FACTORY_CONTACT,
  ADD_OR_REMOVE_ORG_CONTACT
} from "@/store/action.names";

import Organization from "@/views/dashboard/Organization.vue";
import { buildParams, namespaced } from "@/store/utils";
import { ApiPaginationType } from "../../../types/common.types";

function checkEmailExistsError(e: AxiosError): boolean {
  const emailError = (e.response?.data as KeyObj)?.email;
  if (
    emailError &&
    emailError == "contact person with this Email already exists."
  ) {
    return true;
  } else {
    return false;
  }
}

const state: AdminOrganizationState = {};

const getters: GetterTree<AdminOrganizationState, RootState> = {};
const actions: ActionTree<AdminOrganizationState, RootState> = {
  //organization actions
  async [GET_ORGANIZATIONS](
    { rootGetters },
    filters: Api.AdminOrganizationsRetrieve.RequestQuery
  ): Promise<PaginatedOrganizationList | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_ORGANIZATIONS_URL}/${buildParams(filters)}`, {
          headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        })
        .then(({ data }) => {
          resolve(data as PaginatedOrganizationList);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_ORGANIZATION](
    { rootGetters },
    payload:
      | Api.AdminOrganizationsCreate.RequestBody
      | Api.AdminOrganizationsPartialUpdate.RequestBody
  ): Promise<Organization | AxiosError> {
    return new Promise((resolve, reject) => {
      Object.keys(payload as KeyObj).forEach((key: string) => {
        if (!(payload as KeyObj)[key]) {
          delete (payload as KeyObj)[key];
        }
      });
      let method = axios.post;
      let url = `${ADMIN_ORGANIZATIONS_URL}/`;
      const slug = payload.slug || null;
      if (slug != null) {
        delete payload.slug;
      }
      if (slug != null) {
        url = `${url}${slug}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [GET_ORGANIZATION](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminOrganizationsRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_ORGANIZATIONS_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [DELETE_ORGANIZATION](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminOrganizationsDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_ORGANIZATIONS_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // factory actions
  async [GET_FACTORIES](
    { rootGetters },
    filters: Api.AdminFactoriesList.RequestQuery = {}
  ): Promise<Api.AdminFactoriesList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORIES_URL}/${buildParams(filters)}`, {
          headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        })
        .then(({ data }) => {
          resolve(data as PaginatedFactoryListList);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_FACTORY](
    { rootGetters },
    formData:
      | Api.AdminFactoriesCreate.RequestBody
      | Api.AdminFactoriesPartialUpdate.RequestBody
  ): Promise<Api.AdminFactoriesCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_FACTORIES_URL}/`;
      const slug = formData.slug || null;
      if (slug != null) {
        delete formData.slug;
      }
      if (slug != null) {
        url = `${url}${slug}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [FACTORY_BULK_UPLOAD](
    { rootGetters },
    formData: Api.AdminFactoriesBulkCreateCreate.RequestBody
  ): Promise<Api.AdminFactoriesBulkCreateCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_FACTORIES_URL}/bulk_create/`, formData, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_FACTORY](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminFactoriesRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORIES_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_FACTORY_USERS](
    { rootGetters },
    {
      slug,
      filters
    }: { slug: string; filters: Api.AdminFactoryUsersList.RequestQuery }
  ): Promise<Api.AdminFactoryUsersList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORIES_URL}/${slug}/users/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [DELETE_FACTORY](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminFactoriesDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_FACTORIES_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [FETCH_FACTORY_VISITS](
    { rootGetters },
    payload: {
      slug: string;
      params: Api.AdminFactoriesVisitReportsList.RequestQuery;
    }
  ): Promise<Api.AdminFactoriesVisitReportsList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/${buildParams(
            payload.params
          )}`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [FETCH_FACTORY_VISIT](
    { rootGetters },
    payload: { slug: string; id: number }
  ): Promise<Api.AdminFactoriesVisitReportsRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/${payload.id}`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [ADD_FACTORY_VISIT](
    { rootGetters },
    payload: {
      slug: string;
      editID: string;
      content:
        | Api.AdminFactoriesVisitReportsCreate.RequestBody
        | Api.AdminFactoriesVisitReportsPartialUpdate.RequestBody;
    }
  ): Promise<Api.AdminFactoriesVisitReportsCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/`;
      if (payload.editID) {
        method = axios.patch;
        url = `${url}${payload.editID}/`;
      }
      method(url, payload.content, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [DELETE_FACTORY_VISIT](
    { rootGetters },
    payload: { slug: string; id: number }
  ): Promise<Api.AdminFactoriesVisitReportsDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/${payload.id}/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [ADD_FACTORY_VISIT_FILE](
    { rootGetters },
    payload: {
      slug: string;
      id: number;
      content: Api.AdminFactoriesVisitReportsAddFilePartialUpdate.RequestBody;
    }
  ): Promise<
    Api.AdminFactoriesVisitReportsAddFilePartialUpdate.ResponseBody | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/${payload.id}/add_file/`,
          payload.content,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_FACTORY_VISIT_FILE](
    { rootGetters },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_FACTORIES_URL}/${payload.slug}/visit-reports/${payload.id}/remove_file/`,
          payload.content,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_VISIT_SCHEDULE](
    { rootGetters },
    payload: any
  ): Promise<any> {
    const headers = {
      headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
    };
    let method = axios.post;
    const visitPayload = { ...payload };
    let url = ADMIN_FACTORY_VISITS_URL + "/";
    if (visitPayload.id) {
      method = axios.patch;
      url += `${payload.id}/`;
      delete visitPayload.id;
    }
    const { data } = await method(url, visitPayload, headers);
    return data;
  },
  async [DELETE_VISIT_SCHEDULE]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_FACTORY_VISITS_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_VISIT_ITERATION](
    { rootGetters },
    payload: { visit_id: number; iteration: any }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      };
      let method = axios.post;
      let url =
        ADMIN_FACTORY_VISITS_URL + "/" + payload.visit_id + "/iterations/";
      const iterationPayload = { ...payload.iteration };
      if (iterationPayload.id) {
        method = axios.patch;
        url += `${iterationPayload.id}/`;
        delete iterationPayload.id;
      }
      iterationPayload.visit = payload.visit_id;
      method(url, iterationPayload, headers)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
  },
  async [DELETE_VISIT_ITERATION](
    { rootGetters },
    payload: { visit_id: number; iteration_id: number }
  ): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(
          `${ADMIN_FACTORY_VISITS_URL}/${payload.visit_id}/iterations/${payload.iteration_id}/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(() => {
          resolve();
        })

        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_FACTORY_VISIT_STATS]({ rootGetters }): Promise<any> {
    const { data } = await axios.get(ADMIN_FACTORY_VISIT_STATS_URL, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },
  //Contact Person
  async [GET_CONTACT_PERSONS](
    { rootGetters },
    filters: { [key: string]: any } = {}
  ): Promise<PaginatedContactPersonList | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_CONTACT_PERSON_URL}/${buildParams(filters)}`, {
          headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        })
        .then(({ data }) => {
          resolve(data as PaginatedContactPersonList);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_CONTACT_PERSON](
    { rootGetters, dispatch },
    payload: any
  ): Promise<ContactPerson | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_CONTACT_PERSON_URL}/`;
      const id = payload.id || null;
      if (id != null) {
        delete payload.id;
        url = `${ADMIN_CONTACT_PERSON_URL}/${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          const existsError = checkEmailExistsError(e);
          if (existsError) {
            dispatch(GET_CONTACT_PERSONS, { search: payload.email }).then(
              (sdata: any) => {
                if (sdata.results.length > 0) {
                  resolve(sdata.results[0]);
                } else {
                  reject(e);
                }
              }
            );
          } else {
            reject(e);
          }
        });
    });
  },
  async [GET_CONTACT_PERSON]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_CONTACT_PERSON_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADD_OR_REMOVE_FACTORY_COURSE](
    { rootGetters },
    { slug, payload }
  ): Promise<any> {
    const newPayload: any = {};
    if (payload.course_filter_type)
      newPayload.course_filter_type = payload.course_filter_type;
    if (payload.reportable_courses?.length)
      newPayload.reportable_courses = payload.reportable_courses_add;
    if (payload.reportable_courses_remove?.length)
      newPayload.reportable_courses_remove = payload.reportable_courses_remove;
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_FACTORIES_URL}/${slug}/include_exclude_course/`,
          newPayload,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ATTACH_CONTACT_PERSON](
    { rootGetters },
    { type, slug, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const URL = `${ADMIN_API}/${type}/${slug}/add_contact_person/`;
      axios
        .post(URL, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [DETACH_CONTACT_PERSON](
    { rootGetters },
    { type, slug, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const URL = `${ADMIN_API}/${type}/${slug}/remove_contact_person/`;
      axios
        .post(URL, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [FETCH_MASTER_PLANS](
    { rootGetters },
    { fslug, filters }
  ): Promise<ApiPaginationType | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_FACTORIES_URL}/${fslug}/master-plan/${buildParams(filters)}`,
          { headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [MASTER_PLAN_CREATE_OR_UPDATE](
    { rootGetters, dispatch },
    { fslug, payload }
  ): Promise<ContactPerson | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_FACTORIES_URL}/${fslug}/master-plan/`;
      const id = payload.id || null;
      if (id != null) {
        delete payload.id;
        url += `${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GENERATE_QUESTION]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const URL = `${ADMIN_FACTORIES_URL}/${payload.fslug}/generate_question/`;
      axios
        .get(URL, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GENERATE_REPORT]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORIES_URL}/${payload.fslug}/generate_report/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GENERATE_ORGANIZATION_REPORT]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_ORGANIZATION_REPORT}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GENERATE_ALL_ORGANIZATION_REPORT](
    { rootGetters },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_ALL_ORGANIZATION_REPORT}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [FETCH_MASTER_REPORTS]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_ALL_ORGANIZATION_REPORT}/${buildParams(params)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [DELETE_MASTER_REPORT]({ rootGetters }, id: number): Promise<any> {
    return await axios.delete(`${ADMIN_ALL_ORGANIZATION_REPORT}/${id}/`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
  },
  [EXPORT_FACTORY_VISITS]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_VISIT_REPORTS_EXPORTS}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [FETCH_FACTORY_VISITS_EXPORTS]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_VISIT_REPORTS_EXPORTS}/${buildParams(params)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GENERATE_FACTORY_REPORT]({ rootGetters }, { fslug }): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = fslug
        ? `${ADMIN_FACTORIES_URL}/${fslug}/factory_report/`
        : `${ADMIN_FACTORIES_URL}/all_factory_report/`;
      axios
        .get(url, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [CREATE_REPORT]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const fact_slug = data.payload.get("factory");
      axios
        .post(`${ADMIN_FACTORIES_URL}/${fact_slug}/report/`, data.payload, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              data.onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          },
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [FETCH_REPORTS]({ rootGetters }, { fslug, filters }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORIES_URL}/${fslug}/report/${buildParams(filters)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [UPDATE_REPORT]({ rootGetters }, { fact_slug, id, payload }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ADMIN_FACTORIES_URL}/${fact_slug}/report/${id}/`, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [ADD_OR_REMOVE_FACTORY_CONTACT](
    { rootGetters },
    { factory_slug, type, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${ADMIN_FACTORIES_URL}/${factory_slug}/${
        type == "add" ? "add_contact_person" : "remove_contact_person"
      }/`;
      axios
        .patch(url, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [ADD_OR_REMOVE_ORG_CONTACT](
    { rootGetters },
    { org_slug, type, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${ADMIN_ORGANIZATIONS_URL}/${org_slug}/${
        type == "add" ? "add_contact_person" : "remove_contact_person"
      }/`;
      axios
        .patch(url, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [FETCH_FACTORY_VISIT_SCHEDULES](
    { rootGetters },
    filters
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_FACTORY_VISITS_URL}${buildParams(filters)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [FETCH_ORG_STATS]({ rootGetters }, slug: string) {
    const { data } = await axios.get(
      `${ADMIN_ORGANIZATIONS_URL}/${slug}/stats/`,
      {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      }
    );
    return data;
  }
};

const mutations: MutationTree<AdminOrganizationState> = {};

const adminOrgModule: Module<AdminOrganizationState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};

export default adminOrgModule;
