import { METHODS_MAP } from './../../utils';
import type { LessonContent, Exam } from "./../../../types/api.types";
import { KeyObj } from "@/types/common.types";
import axios, { Method, AxiosError } from "axios";
import { Vue } from "vue-property-decorator";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";

import {
  AdminCourseDataState,
  RootState,
  AdminCourseData
} from "../../../types/store.types";
import type { Api, PaginatedCourseList } from "@/types/api.types";

import {
  ADMIN_COURSES_URL,
  ADMIN_PACKAGES_URL,
  ADMIN_USERS_COURSES
} from "@/store/endpoints";

import {
  GET_COURSES,
  GET_PACKAGES,
  ADD_OR_EDIT_PACKAGES,
  DELETE_PACKAGES,
  CREATE_OR_EDIT_COURSE,
  GET_COURSE_BY_SLUG,
  COURSE_PERMISSION,
  DELETE_COURSE_CONTENT,
  DELETE_DEEP_CONTENT,
  UPDATE_COURSE_PUBLICATION,
  GET_COURSE_CONTENTS,
  GET_DEEP_CONTENTS,
  ORDER_COURSE_CONTENT,
  DELETE_MODULE,
  CREATE_OR_EDIT_COURSE_CONTENT,
  GET_COURSE_MODULES,
  CREATE_OR_EDIT_COURSE_MODULE,
  ORDER_MODULES,
  GET_COURSES_BY_USER,
  CREATE_OR_EDIT_LESSON_CONTENT,
  DELETE_COURSE,
  MOVE_CONTENT_ACROSS_MODULE,
  FETCH_COURSE_FEEDBACK_QUESTION,
  COURSE_FEEDBACK_QUESTION_ORDER,
  FEEDBACK_REPORT,
  COLLECTED_QUESTION,
  FETCH_COURSE_MANUALS,
  DELETE_COURSE_MANUAL,
  CREATE_OR_UPDATE_COURSE_MANUAL,
  ORDER_COURSE_MANUAL
} from "../../action.names";

import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER, COURSES } from "../../getter.names";
import { SET_COURSE_DATA } from "../../mutation.names";

import { namespaced, buildParams } from "../../utils";
import { Key } from "readline";
import {
  ContentMoveAcrossModulesRequest,
  DeeperCourseContentRequest
} from "@/types/additionalApi.types";

const state: AdminCourseDataState = {
  courseData: {
    courses: [],
    error: false
  }
};

const getters: GetterTree<AdminCourseDataState, RootState> = {
  [COURSES](store: AdminCourseDataState) {
    return store.courseData.courses;
  }
};

const actions: ActionTree<AdminCourseDataState, RootState> = {
  async [GET_PACKAGES](
    { rootGetters },
    filters: Api.AdminPackagesList.RequestQuery = {}
  ): Promise<Api.AdminPackagesList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_PACKAGES_URL}/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [ADD_OR_EDIT_PACKAGES](
    { rootGetters },
    formData:
      | Api.AdminPackagesCreate.RequestBody
      | Api.AdminPackagesPartialUpdate.RequestBody
  ): Promise<Api.AdminPackagesCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_PACKAGES_URL}/`;
      const id = formData.id || null;
      if (id != null) {
        delete formData.id;
      }
      if (id != null) {
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [DELETE_PACKAGES](
    { rootGetters },
    id: number
  ): Promise<Api.AdminPackagesDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_PACKAGES_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_COURSES](
    { rootGetters, commit },
    filters: Api.AdminCoursesList.RequestQuery
  ): Promise<Api.AdminCoursesList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_COURSES_URL}/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          commit(SET_COURSE_DATA, { courses: data, error: false });
          resolve(data as PaginatedCourseList);
        })
        .catch((e: AxiosError) => {
          commit(SET_COURSE_DATA, {
            courses: [],
            error: true
          });
          reject(e);
        });
    });
  },
  async [CREATE_OR_EDIT_COURSE](
    { rootGetters },
    formData:
      | Api.AdminCoursesCreate.RequestBody
      | Api.AdminCoursesPartialUpdate.RequestBody
  ): Promise<Api.AdminCoursesCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_COURSES_URL}/`;
      const slug = formData.slug || null;
      if (slug != null) {
        delete formData.slug;
      }
      if (slug != null) {
        url = `${url}${slug}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_BY_SLUG](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminCoursesRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_COURSES_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [DELETE_COURSE](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminCoursesDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_COURSES_URL}/${slug}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [COURSE_PERMISSION](
    { rootGetters },
    slug: string
  ): Promise<KeyObj | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_COURSES_URL}/${slug}/content_permission/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_MODULES](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminCoursesModulesList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_COURSES_URL}/${slug}/modules/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_CONTENTS](
    { rootGetters },
    data: Api.AdminCoursesModulesContentsList.RequestParams
  ): Promise<Api.AdminCoursesModulesContentsList.RequestBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_EDIT_COURSE_MODULE](
    { rootGetters },
    {
      slug,
      formData
    }: {
      slug: string;
      formData:
        | Api.AdminCoursesModulesCreate.RequestBody
        | Api.AdminCoursesModulesPartialUpdate.RequestBody;
    }
  ): Promise<Api.AdminCoursesModulesCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_COURSES_URL}/${slug}/modules/`;
      if (formData.id) {
        method = axios.patch;
        url += `${formData.id}/`;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [ORDER_MODULES](
    { rootGetters },
    data: { slug: string; id: number, payload: any }
  ): Promise<KeyObj | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.id}/order/`,
          data.payload,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [DELETE_MODULE](
    { rootGetters },
    data: Api.AdminCoursesModulesDestroy.RequestParams
  ): Promise<Api.AdminCoursesModulesDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_COURSES_URL}/${data.slug}/modules/${data.id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_EDIT_COURSE_CONTENT](
    { rootGetters },
    data: {
      slug: string;
      module: number;
      formData:
        | Api.AdminCoursesModulesContentsCreate.RequestBody
        | Api.AdminCoursesModulesContentsPartialUpdate.RequestBody;
    }
  ): Promise<Api.AdminCoursesModulesContentsCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/`;
      if (data.formData.content_object?.id != undefined) {
        url = `${url}${data.formData.content_object.id}/`;
        delete data.formData.content_object.id;
        method = axios.patch;
      }
      method(url, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [ORDER_COURSE_CONTENT](
    { rootGetters },
    data: {
      slug: string;
      id: number;
      module: number;
      payload: number[];
    }
  ): Promise<KeyObj | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.id}/order/`,
          data.payload,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [DELETE_COURSE_CONTENT](
    { rootGetters },
    data: Api.AdminCoursesModulesContentsDestroy.RequestParams
  ): Promise<Api.AdminCoursesModulesContentsDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.id}/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [MOVE_CONTENT_ACROSS_MODULE](
    { rootGetters },
    data: { slug: string; module: number, formData: ContentMoveAcrossModulesRequest }
  ): Promise<KeyObj | AxiosError> {
    return new Promise((resolve, reject) => {
      const method = axios.post;
      const url = `${ADMIN_COURSES_URL}/${data.slug}/modules/${module}/move_content/`;
      method(url, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [COLLECTED_QUESTION](
    { rootGetters },
    {
      slug,
      contentId,
      filters
    }: {
      slug: string;
      contentId: number;
      filters: Api.AdminCoursesCollectedQuestionList.RequestQuery;
    }
  ): Promise<Api.AdminCoursesCollectedQuestionList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_COURSES_URL}/${slug}/collected-question/${contentId}/${buildParams(
            filters
          )}`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_EDIT_LESSON_CONTENT](
    { rootGetters },
    data: DeeperCourseContentRequest
  ): Promise<LessonContent | Exam | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/`;
      // console.log(data.formData);
      if (data.formData.id != undefined) {
        url = `${url}${data.formData.id}/`;
        delete data.formData.id;
        method = axios.patch;
      }
      method(url, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [GET_DEEP_CONTENTS](
    { rootGetters },
    data: { slug: string; module: number; course_content: number }
  ): Promise<LessonContent | Exam | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [DELETE_DEEP_CONTENT](
    { rootGetters },
    data: { slug: string; module: number; course_content: number; id: number }
  ): Promise<never | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/${data.id}/`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [UPDATE_COURSE_PUBLICATION](
    { rootGetters },
    payload: { is_published: boolean; slug: string }
  ): Promise<KeyObj | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_COURSES_URL}/${payload.slug}/change_is_published/`,
          { is_published: payload.is_published },
          { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] } }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [GET_COURSES_BY_USER](
    { rootGetters },
    filters: Api.AdminEnrollmentsList.RequestQuery
  ): Promise<Api.AdminEnrollmentsList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_USERS_COURSES}/${buildParams(filters)}`, {
          headers: rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },




  [FETCH_COURSE_FEEDBACK_QUESTION](
    { rootGetters },
    data: {
      slug: string;
      type: string;
      id?: number;
      payload?:
        | Api.AdminCoursesFeedbackQuestionList.RequestBody
        | Api.AdminCoursesFeedbackQuestionCreate.RequestBody
        | Api.AdminCoursesFeedbackQuestionDestroy.RequestBody
        | Api.AdminCoursesFeedbackQuestionRetrieve.RequestQuery
        | Api.AdminCoursesFeedbackQuestionUpdate.RequestBody
        | Api.AdminCoursesFeedbackQuestionPartialUpdate.RequestBody;
    }
  ): Promise<
    | Api.AdminCoursesFeedbackQuestionList.ResponseBody
    | Api.AdminCoursesFeedbackQuestionCreate.ResponseBody
    | Api.AdminCoursesFeedbackQuestionDestroy.ResponseBody
    | Api.AdminCoursesFeedbackQuestionRetrieve.ResponseBody
    | Api.AdminCoursesFeedbackQuestionUpdate.ResponseBody
    | Api.AdminCoursesFeedbackQuestionPartialUpdate.ResponseBody
    | AxiosError
  > {
    return new Promise((resolve, reject) => {
      const type = data.type; // for list type
      let url = `${ADMIN_COURSES_URL}/${data.slug}/feedback/question/`;
      const method = METHODS_MAP[type];
      if (method != "post" && data.id) {
        url +=`${data.id}/`
      }
      axios({
        method,
        url,
        data: data.payload,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      }).then(({ data }) => {
        resolve(data);
      }).catch(e => {
        reject(e)
      });
    });
  },
  [COURSE_FEEDBACK_QUESTION_ORDER]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${ADMIN_COURSES_URL}/${data.slug}/feedback/question/order/`,
          data.payload,
          { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] } }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [FEEDBACK_REPORT](
    { rootGetters },
    { slug }: { slug: string }
  ): Promise<Api.AdminCoursesFeedbackReportRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_COURSES_URL}/${slug}/feedback_report/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [FETCH_COURSE_MANUALS]({ rootGetters }, slug: string): Promise<any> {
   const {data} = await axios.get(`${ADMIN_COURSES_URL}/${slug}/manuals/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      });
      return data;
  },
  async [DELETE_COURSE_MANUAL]({ rootGetters }, { slug, id }: { slug: string, id: number }): Promise<any> {
    const {data} = await axios.delete(`${ADMIN_COURSES_URL}/${slug}/manuals/${id}/`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
    return data;
  },
  async [CREATE_OR_UPDATE_COURSE_MANUAL]({ rootGetters }, { slug, formData }: { slug: string, formData: any }): Promise<any> {
    let url = `${ADMIN_COURSES_URL}/${slug}/manuals/`;
    let method = axios.post;
    if (formData.id) {
      url += `${formData.id}/`;
      method = axios.patch;
      delete formData.id;
    }
    const {data} = await method(url, formData, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
    return data;
  },
  async [ORDER_COURSE_MANUAL]({ rootGetters }, { slug, payload }: { slug: string, payload: { id: number, order: number } }): Promise<any> {
    const {data} = await axios.patch(`${ADMIN_COURSES_URL}/${slug}/manuals/${payload.id}/order/`, {order: payload.order}, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
    return data;
  }
};

const mutations: MutationTree<AdminCourseDataState> = {
  [SET_COURSE_DATA](store, payload: AdminCourseData) {
    Vue.set(store, "courseData", payload);
  }
};

const adminCourseData: Module<AdminCourseDataState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state
};

export default adminCourseData;
