<template>
  <div class="footer">
    <div class="footer-wrapper">
      <b-container fluid class="p-0">
        <b-row>
          <b-col lg="5" xl="5" md="12" sm="12" cols="12">
            <div class="left-content">
              <div class="logo">
                <img :src="require('@/assets/pink-logo.svg')" alt="Logo" />
              </div>

              <p>
                {{ footer.text }}
              </p>

              <b-row>
                <b-col lg="6" xl="6" md="6" sm="6" cols="12">
                  <div class="contact-info">
                    <a href="#">
                      <img :src="require('@/assets/phone-icon.svg')" alt="" />{{
                        footer.phone
                      }}</a
                    >
                  </div>
                </b-col>

                <b-col lg="6" xl="6" md="6" sm="6" cols="12">
                  <div class="contact-info">
                    <a href="#">
                      <img :src="require('@/assets/email-icon.svg')" alt="" />{{
                        footer.email
                      }}</a
                    >
                  </div>
                </b-col>
              </b-row>

              <div class="footer-social-icon">
                <a
                  v-if="socialMedia.youtube_url"
                  :href="socialMedia.youtube_url"
                  ><img
                    :src="require('@/assets/youtube-icon.svg')"
                    alt="Youtube"
                /></a>
                <a
                  v-if="socialMedia.facebook_url"
                  :href="socialMedia.facebook_url"
                  ><img
                    :src="require('@/assets/facebook-icon.svg')"
                    alt="facebook "
                /></a>
                <a
                  v-if="socialMedia.twitter_url"
                  :href="socialMedia.twitter_url"
                  ><img
                    :src="require('@/assets/twitter-icon.svg')"
                    alt="Youtube"
                /></a>
              </div>
            </div>
          </b-col>

          <b-col
            lg="6"
            xl="6"
            offset-lg="1"
            offset-xl="1"
            sm="12"
            md="12"
            cols="12"
          >
            <b-row>
              <!-- <b-col lg="4" xl="4" sm="4" md="4" cols="12">
                <div class="flat-menus">
                  <h3>Category</h3>
                  <ul>
                    <router-link to="">Industry</router-link>
                    <router-link to="">Technology</router-link>
                    <router-link to="">Marketing</router-link>
                    <router-link to="">Business</router-link>
                    <router-link to="">Health</router-link>
                  </ul>
                </div>
              </b-col> -->
              <b-col lg="4" xl="4" sm="4" md="4" cols="12">
                <!-- <div class="flat-menus">
                  <h3>Quick Links</h3>
                  <ul>
                    <router-link to="/">Home</router-link>
                    <router-link to="/profile">Profile</router-link>
                    <router-link to="/dashboard">Dashboard</router-link>
                    <router-link to="/faq">FAQs</router-link>
                    <router-link to="">Notifications</router-link>
                  </ul>
                </div> -->
                <div
                  class="flat-menus"
                  v-for="(flatMenu, idx) in flatMenus"
                  :key="'flat-menu' + idx"
                >
                  <h3>{{ flatMenu.title }}</h3>

                  <ul>
                    <router-link
                      v-for="(menuItem, idx) in flatMenu.menu_items"
                      :key="'menu-item' + idx"
                      :to="menuItem.link"
                      >{{ menuItem.link_text }}</router-link
                    >
                  </ul>
                </div>
              </b-col>
              <!-- <b-col lg="4" xl="4" sm="4" md="4" cols="12">
                <div class="flat-menus">
                  <h3>Connect</h3>
                  <ul>
                    <router-link to="">Industry</router-link>
                    <router-link to="">Technology</router-link>
                    <router-link to="">Marketing</router-link>
                    <router-link to="">Business</router-link>
                    <router-link to="">Health</router-link>
                  </ul>
                </div>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-wrapper">
        <b-container fluid class="p-0">
          <b-row>
            <b-col lg="6" xl="6" md="6" sm="12" cols="12">
              <p>{{ footer.copyright }}</p>
            </b-col>
            <b-col lg="6" xl="6" md="6" sm="12" cols="12">
              <p class="footer-right-text">
                Developed by:
                <a
                  href="http://codesign.com.bd"
                  target="_blank"
                  rel="noopener noreferrer"
                  >COdesign</a
                >
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import {
  Footer as FooterType,
  FlatMenu,
  SocialMedia
} from "../../types/cms.types";

@Component({
  name: "Footer",
  components: {}
})
export default class Footer extends Vue {
  @Prop() footer!: FooterType;
  @Prop() flatMenus!: FlatMenu[];
  @Prop() socialMedia!: SocialMedia;
}
</script>
<style scoped lang="scss">
.footer {
  .footer-wrapper {
    width: 75%;
    padding: 5rem 0;
    margin: 0 auto;
  }
}

.left-content {
  @media only screen and (max-width: 993px) {
    margin-bottom: 35px;
  }
  .logo {
    margin-bottom: 45px;
    width: 110px;
    img {
      width: 100%;
    }
  }

  p {
    @include ParagraphText;
    color: #777777;
    margin-bottom: 45px;
    @include responsive-font(0.8vw, 16px, 22px, 22px);
  }

  .contact-info {
    margin-bottom: 45px;
    a {
      @include ParagraphText;
      text-decoration: none;
      color: #777777;
      display: flex;
      align-items: center;
      @include responsive-font(0.8vw, 16px, 22px, 22px);
      img {
        margin-right: 15px;
      }
    }
  }
}

.footer-social-icon {
  display: flex;
  align-items: center;
  a {
    width: 25px;
    display: flex;
    height: 25px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    justify-content: center;
    img {
      width: 100%;
    }
  }
}

.flat-menus {
  h3 {
    @include Title;
    @include responsive-font(1.1vw, 18px, 20px, 16px);
    margin-bottom: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
      @include ParagraphText;
      @include responsive-font(0.8vw, 16px, 22px, 22px);
      color: #777777;
      margin-bottom: 9px;
    }
  }
}

.footer-right-text {
  text-align: right;

  @media only screen and (max-width: 769px) {
    text-align: center;
  }
}

.footer-bottom {
  background: #fff;
  padding: 1rem 0rem;
}

.footer-bottom-wrapper {
  width: 75%;
  margin: 0 auto;

  @media only screen and (max-width: 993px) {
    width: 85%;
  }

  @media only screen and (max-width: 769px) {
    text-align: center;
  }

  p {
    margin: 0;
  }
}
</style>
