import type { Api } from "@/types/api.types";
import axios from "axios";
import { AxiosError } from "axios";
import { Vue } from "vue-property-decorator";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { AdminBillingState, RootState } from "@/types/store.types";
import { FullBillingInfo } from "@/types/additionalApi.types";

import { ADMIN_BILLING_URL } from "@/store/endpoints";

import {
  GET_BILLINGS,
  CREATE_OR_UPDATE_BILLINGS,
  CREATE_OR_UPDATE_BILLING_SERVICES,
  DELETE_BILLING_SERVICES,
  CREATE_OR_UPDATE_BILLING_PAYMENT,
  GET_BILLING,
  DELETE_BILLING,
  GET_BILLING_FULL_DETAILS
} from "../../action.names";
import { SET_BILLING_FULL_DATA } from "../../mutation.names";
import { AUTH_HEADER, BILLING_FULL_DETAILS } from "../../getter.names";
import { NS_USER } from "../../namespace.names";

import { buildParams, namespaced } from "../../utils";

const state: AdminBillingState = {
  fullBillingInfo: {
    total_amount: null,
    payment_amount: null,
    payment_clear_factory: null,
    challan_tax_amount: null,
    challan_vat_amount: null
  }
};
const getters: GetterTree<AdminBillingState, RootState> = {
  [BILLING_FULL_DETAILS](store: AdminBillingState) {
    return store.fullBillingInfo;
  }
};
const actions: ActionTree<AdminBillingState, RootState> = {
  async [GET_BILLINGS](
    { rootGetters },
    filters: Api.AdminBillingList.RequestQuery = {}
  ): Promise<Api.AdminBillingList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_BILLING_URL}/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [GET_BILLING](
    { rootGetters },
    id: number
  ): Promise<Api.AdminBillingRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_BILLING_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  [GET_BILLING_FULL_DETAILS]({
    rootGetters,
    commit
  }): Promise<
    Api.AdminBillingBillingDetailsRetrieve.ResponseBody | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_BILLING_URL}/billing_details/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          commit(SET_BILLING_FULL_DATA, data);
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [DELETE_BILLING](
    { rootGetters },
    id: number
  ): Promise<Api.AdminBillingDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_BILLING_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_UPDATE_BILLINGS](
    { rootGetters },
    formData:
      | Api.AdminBillingCreate.RequestBody
      | Api.AdminBillingPartialUpdate.RequestBody
  ): Promise<Api.AdminBillingCreate.RequestBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_BILLING_URL}/`;
      const id = formData.id || null;
      if (id != null) {
        delete formData.id;
      }
      if (id != null) {
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [DELETE_BILLING_SERVICES](
    { rootGetters },
    { billingID, serviceID }
  ): Promise<Api.AdminBillingServiceDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_BILLING_URL}/${billingID}/service/${serviceID}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_UPDATE_BILLING_SERVICES](
    { rootGetters },
    formData:
      | Api.AdminBillingServiceCreate.RequestBody
      | Api.AdminBillingServicePartialUpdate.RequestBody
  ): Promise<Api.AdminBillingServiceCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_BILLING_URL}/${formData.billing_id}/service/`;
      const id = formData.id || null;
      if (id != null) {
        delete formData.id;
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_UPDATE_BILLING_PAYMENT](
    { rootGetters },
    formData: {
      billing_id: number;
      payload:
        | Api.AdminBillingPaymentCreate.RequestBody
        | Api.AdminBillingPaymentPartialUpdate.RequestBody;
    }
  ): Promise<Api.AdminBillingPaymentCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_BILLING_URL}/${formData.billing_id}/payment/`;
      const id = formData.payload.id || null;
      if (id != null) {
        delete formData.payload.id;
        url = `${url}/${id}/`;
        method = axios.patch;
      }
      method(url, formData.payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  }
};
const mutations: MutationTree<AdminBillingState> = {
  [SET_BILLING_FULL_DATA](store, payload: FullBillingInfo) {
    Vue.set(store, "fullBillingInfo", payload);
  }
};

const adminBillingModule: Module<AdminBillingState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};

export default adminBillingModule;
