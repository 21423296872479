import { Route } from "vue-router";
import Vue from "vue";
import "./routerHooks";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import { setInteractionMode } from "vee-validate";
import { namespaced } from "./store/utils";
import { NS_USER } from "./store/namespace.names";
import { GET_TOKEN_FROM_LOCAL_STORE } from "./store/action.names";
import { TOKEN,  PROFILE } from "./store/getter.names";
import Dayjs from "vue-dayjs";
import "vue2-datepicker/index.css";
import "vue-select/src/scss/vue-select.scss";
import VCalendar from "v-calendar";
import VueYoutube from "vue-youtube";
import Toasted from "vue-toasted";
import { isFunction } from "lodash";

Vue.use(Dayjs, {
  lang: "en"
});
Vue.use(VueYoutube);

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

setInteractionMode("passive");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import VueEllipseProgress from "vue-ellipse-progress";
import { LOGIN_REQUIRED } from "./utils/user";

Vue.use(VueEllipseProgress);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toasted, {
  duration: 3000
});

function checkAuth(to: Route, from: Route, next: any) {
  if (to.meta?.accessLevel != null || to.meta?.accessLevel != undefined) {
    const loggedIn = store.getters[namespaced(NS_USER, TOKEN)] != null;
    if (!loggedIn) {
      next("/login");
    } else if (to.meta?.accessLevel == LOGIN_REQUIRED) {
      next();
    } else if (
      isFunction(to.meta?.accessLevel) &&
      to.meta?.accessLevel(store.getters[namespaced(NS_USER, PROFILE)], to)
    ) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  // store.commit(namespaced(NS_PUBLIC, SET_COURSE_SEARCH_RESULTS), null);
  if (from == null || from.name == null) {
    store.dispatch(namespaced(NS_USER, GET_TOKEN_FROM_LOCAL_STORE)).then(() => {
      checkAuth(to, from, next);
    });
  } else {
    checkAuth(to, from, next);
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
