import { UserNotification } from "./../../types/store.types";
import { KeyObj } from "@/types/common.types";
import axios, { AxiosResponse } from "axios";
import { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { UserDataState, RootState } from "../../types/store.types";
import type { Api, PublicProfile } from "@/types/api.types";
import { buildParams } from "../utils";
import { InviteUsers } from "../../types/additionalApi.types";

import {
  LOGIN_URL,
  PROFILE_URL,
  LOGOUT_URL,
  USER_PASSWORD,
  INVITATION_URL,
  ADMIN_USERS_URL,
  INVITE_USER_URL,
  INVITE_BATCH_USER_URL,
  BATCH_USER_ASSIGN_URL,
  NOTIFICATIONS_URL,
  ADMIN_STATS_URL
} from "../endpoints";
import {
  PROFILE,
  TOKEN,
  NOTIFICATION,
  AUTH_HEADER,
  IS_FOCAL,
  HAS_PERM,
  HAS_PERMS,
  HAS_ANY_PERM,
  FIND_FIRST_AVAILABLE_PERM,
  DASHBOARD_ACCESS,
  GET_PERMISSIONS_MAP,
  IS_SUPERUSER
} from "../getter.names";
import {
  LOGIN,
  GET_PROFILE,
  UPDATE_PROFILE,
  LOGOUT,
  GET_TOKEN_FROM_LOCAL_STORE,
  SET_PASSWORD,
  PASSWORD_RESET,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  GET_USERS,
  INVITE_USER,
  INVITE_BATCH_USER,
  GET_USER,
  UPDATE_USER_ADMIN,
  DOWNLOAD_USER_INFO,
  GET_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  FETCH_ADMIN_STATS,
  SET_ADMIN_COMMENT,
  UPDATE_SUPERUSER_STATUS
} from "../action.names";
import {
  CLEAR_PROFILE_DATA,
  SET_TOKEN,
  SET_TOKEN_ERROR,
  SET_PROFILE_DATA,
  SET_NOTIFICATION
} from "../mutation.names";

import { BATCH_USER_ASSIGN } from "../action.names";
import { hasAnyPerm, hasPerm, hasPerms } from "@/utils/user";
import {
  FACTORY_VISIT_STATS_PERM,
  MANAGE_OWN_FACTORY_VISITS_PERM,
  VIEW_ADMIN_LOG_ENTRY_PERM,
  VIEW_AGREEMENT_PERM,
  VIEW_COURSE_PERM,
  VIEW_FACTORY_VISIT_PERM,
  VIEW_FAQ_PERM,
  VIEW_GROUP_PERM,
  VIEW_MASTER_REPORT_PERM,
  VIEW_ORGANIZATION_BILLING_PERM,
  VIEW_ORGANIZATION_PERM,
  VIEW_PROFILE_PERM,
  VIEW_TRAINING_ACTION_PERM,
  VIEW_USER_INVITATION_PERM,
  VIEW_VISIT_REPORTS_EXPORT_PERM
} from "@/utils/permission.names";

const namespaced = true;

const state: UserDataState = {
  user: {
    profile: null,
    token: null
  },
  notification: {
    count: 0,
    next: null,
    previous: null,
    total_unread: 0,
    results: [],
    is_make_unread: false
  },
  error: false
};

const getters: GetterTree<UserDataState, RootState> = {
  [PROFILE](state: UserDataState): PublicProfile | null {
    return state.user.profile;
  },

  [IS_SUPERUSER](state: any) {
    if (!state.user?.profile) return false;
    return state.user.profile.user.is_superuser;
  },
  [HAS_PERM]: (state) => (perm: string) => {
    if (!state.user?.profile) return false;
    return hasPerm(state.user.profile.user, perm);
  },
  [HAS_PERMS]: (state) => (perms: string[]) => {
    if (!state.user?.profile) return false;
    return hasPerms(state.user.profile.user, perms);
  },
  [GET_PERMISSIONS_MAP]: (state) => (perms: any) => {
    const auth = !state.user?.profile ? false : true;
    const is_superuser = auth && (state as any).user.profile.user.is_superuser;
    const permissions: any = {};
    Object.keys(perms).forEach((key) => {
      permissions[key] =
        auth &&
        (is_superuser || hasPerm((state as any).user.profile.user, perms[key]));
    });
    return permissions;
  },
  [HAS_ANY_PERM]: (state) => (perms: string[]) => {
    if (!state.user?.profile) return false;
    return hasAnyPerm(state.user.profile.user, perms);
  },
  [FIND_FIRST_AVAILABLE_PERM]: (state) => (perms: string[]) => {
    if (!state.user?.profile) return undefined;
    if ((state.user.profile.user as any).is_superuser) return perms[0];
    for (let i = 0; i < perms.length; i++) {
      const perm = perms[i];
      if (hasPerm(state.user.profile.user, perm)) {
        return perm;
      }
    }
    return undefined;
  },
  [TOKEN](state: UserDataState): string | null {
    return state.user.token;
  },
  [IS_FOCAL](state: UserDataState): boolean | null {
    const has_focal = state.user?.profile?.user?.factory_access.find((v) => {
      return v.is_focal == true;
    });
    return !!has_focal;
  },
  [DASHBOARD_ACCESS](state): any | null {
    if (!state.user?.profile) return null;
    const perms = [
      VIEW_COURSE_PERM,
      VIEW_MASTER_REPORT_PERM,
      VIEW_ADMIN_LOG_ENTRY_PERM,
      VIEW_AGREEMENT_PERM,
      VIEW_ORGANIZATION_PERM,
      VIEW_TRAINING_ACTION_PERM,
      VIEW_PROFILE_PERM,
      VIEW_GROUP_PERM,
      VIEW_USER_INVITATION_PERM,
      FACTORY_VISIT_STATS_PERM,
      VIEW_FACTORY_VISIT_PERM,
      MANAGE_OWN_FACTORY_VISITS_PERM,
      VIEW_VISIT_REPORTS_EXPORT_PERM,
      VIEW_ORGANIZATION_BILLING_PERM,
      VIEW_FAQ_PERM
    ];
    let availablePermission = null;
    for (let i = 0; i < perms.length; i++) {
      const perm = perms[i];
      if (hasPerm(state.user.profile.user, perm)) {
        availablePermission = perm;
        break;
      }
    }
    if (availablePermission == null) {
      const has_focal_access = state.user?.profile?.user?.factory_access.find(
        (v) => {
          return v.is_focal == true;
        }
      );
      if (has_focal_access) {
        return { name: "admin-organization" };
      }
    } else {
      switch (availablePermission) {
        case VIEW_COURSE_PERM:
          return { name: "admin-courses" };
        case VIEW_MASTER_REPORT_PERM:
          return { name: "admin-master-reports" };
        case VIEW_ADMIN_LOG_ENTRY_PERM:
          return { name: "admin-logs" };
        case VIEW_AGREEMENT_PERM:
          return { name: "admin-agreements" };
        case VIEW_ORGANIZATION_PERM:
          return { name: "admin-organization" };
        case VIEW_TRAINING_ACTION_PERM:
          return { name: "admin-action-plans" };
        case VIEW_PROFILE_PERM:
          return { name: "admin-users" };
        case VIEW_GROUP_PERM:
          return { name: "admin-groups" };
        case VIEW_USER_INVITATION_PERM:
          return { name: "admin-invitations" };
        case FACTORY_VISIT_STATS_PERM:
          return { name: "admin-visits-overview" };
        case MANAGE_OWN_FACTORY_VISITS_PERM:
        case VIEW_FACTORY_VISIT_PERM:
          return { name: "admin-visits-all" };
        case VIEW_VISIT_REPORTS_EXPORT_PERM:
          return { name: "admin-visits-exports" };
        case VIEW_ORGANIZATION_BILLING_PERM:
          return { name: "admin-billing" };
        case VIEW_FAQ_PERM:
          return { name: "admin-support-faq" };
      }
    }
    return null;
  },
  [AUTH_HEADER](state): KeyObj {
    // console.log(state.user.token);
    if (state.user.token == null) return {};
    return { Authorization: `Token ${state.user.token}` };
  },
  [NOTIFICATION](state): UserNotification {
    return state.notification;
  }
};

const actions: ActionTree<UserDataState, RootState> = {
  async [LOGIN](
    { commit, dispatch },
    loginData: Api.AuthLoginCreate.RequestBody
  ): Promise<Api.AuthLoginCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      // console.log(loginData);
      axios
        .post(LOGIN_URL + "/", loginData)
        .then(({ data }) => {
          // console.log(data);
          commit(SET_TOKEN, data.key);
          dispatch(GET_PROFILE);
          resolve(data.key);
        })
        .catch((e) => {
          commit(SET_TOKEN_ERROR);
          reject(e);
        });
    });
  },
  async [GET_PROFILE]({
    commit,
    getters
  }): Promise<Api.PublicProfileRetrieve.ResponseBody> {
    return new Promise((resolve, reject) => {
      axios
        .get(PROFILE_URL + "/", {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then(({ data }) => {
          commit(SET_PROFILE_DATA, data);
          resolve(data);
        })
        .catch((e: any) => {
          commit(SET_TOKEN_ERROR);
          reject(e);
        });
    });
  },
  async [UPDATE_PROFILE](
    { commit, getters },
    payload:
      | Api.PublicProfilePartialUpdate.RequestBody
      | Api.PublicProfileUpdate.RequestBody = {}
  ): Promise<Api.PublicProfileUpdate.ResponseBody | AxiosError> {
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios
        .patch(PROFILE_URL + "/", payload, {
          headers: { ...getters[AUTH_HEADER] }
        })
        .then(({ data }) => {
          commit(SET_PROFILE_DATA, data);
          resolve(data);
        })
        .catch((e) => {
          // console.log(e);
          reject(e);
        });
    });
  },
  
  async [LOGOUT]({
    commit,
    getters
  }): Promise<Api.AuthLogoutCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          LOGOUT_URL + "/",
          {},
          {
            headers: {
              ...getters[AUTH_HEADER]
            }
          }
        )
        .then((data: any) => {
          commit(CLEAR_PROFILE_DATA);
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_TOKEN_FROM_LOCAL_STORE]({
    commit,
    dispatch
  }): Promise<Api.PublicProfileRetrieve.ResponseBody | AxiosError | KeyObj> {
    return new Promise((resolve, reject) => {
      const localToken = localStorage.getItem("SCPP_TOKEN");
      if (localToken != null) {
        commit(SET_TOKEN, localToken);
        dispatch(GET_PROFILE)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            // console.log("clearing");
            commit(CLEAR_PROFILE_DATA);
            reject(e);
          });
      } else {
        resolve({ message: "Token not exists" });
      }
    });
  },
  async [SET_PASSWORD](
    ctx,
    data: { code: string; password: string }
  ): Promise<
    | AxiosResponse<Api.PublicUserInviteSetPasswordCreate.ResponseBody>
    | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .post(`${INVITATION_URL}/${data.code}/set_password/`, {
          password: data.password
        })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [PASSWORD_RESET](
    { getters },
    data: Api.AuthPasswordResetCreate.RequestBody
  ): Promise<
    AxiosResponse<Api.AuthPasswordResetCreate.ResponseBody> | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USER_PASSWORD}/password_reset/`, data, {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FORGOT_PASSWORD](
    ctx,
    data: Api.PublicPasswordForgotPasswordCreate.RequestBody
  ): Promise<
    | AxiosResponse<Api.PublicPasswordForgotPasswordCreate.ResponseBody>
    | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USER_PASSWORD}/forgot_password/`, data)
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [CONFIRM_FORGOT_PASSWORD](
    ctx,
    data: Api.PublicPasswordConfirmForgotPasswordCreate.RequestBody
  ): Promise<
    | AxiosResponse<Api.PublicPasswordConfirmForgotPasswordCreate.ResponseBody>
    | AxiosError
  > {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USER_PASSWORD}/confirm_forgot_password/`, data)
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  //ADMIN
  async [INVITE_USER](
    { getters },
    formData: any
  ): Promise<InviteUsers | AxiosError> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${INVITE_USER_URL}/`;
      const slug = formData.slug || null;
      if (slug != null) {
        delete formData.slug;
      }
      if (slug != null) {
        url = `${url}${slug}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...getters[AUTH_HEADER]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [INVITE_BATCH_USER]({ getters }, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${INVITE_BATCH_USER_URL}/`, formData, {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [BATCH_USER_ASSIGN]({ getters }, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BATCH_USER_ASSIGN_URL}/`, formData, {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [DOWNLOAD_USER_INFO]({ getters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_USERS_URL}/download_user_info/${buildParams(params)}`, {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  [GET_NOTIFICATIONS]({ commit, state, getters }, params: any = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${NOTIFICATIONS_URL}/${buildParams(params)}`, {
          headers: {
            ...getters[AUTH_HEADER]
          }
        })
        .then(({ data }) => {
          const is_make_unread = state.notification.results.length > 0;
          data.results = [...state.notification.results, ...data.results];
          commit(SET_NOTIFICATION, {
            data: {
              ...data,
              is_make_unread: is_make_unread
            }
          });
          resolve({ total_unread: data.total_unread });
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [READ_NOTIFICATIONS]({ commit, state, getters }) {
    axios
      .get(`${NOTIFICATIONS_URL}/make_reads/`, {
        headers: {
          ...getters[AUTH_HEADER]
        }
      })
      .then(({ data }) => {
        commit(SET_NOTIFICATION, {
          data: {
            ...state.notification,
            is_make_unread: true
          }
        });
      });
    // .catch((e: any) => {
    //   console.log(e);
    // });
  },
  async [FETCH_ADMIN_STATS]({ getters }) {
    try {
      const { data } = await axios.get(ADMIN_STATS_URL, {
        headers: {
          ...getters[AUTH_HEADER]
        }
      });
      return data;
    } catch (e) {
      return e;
    }
  }
};

const mutations: MutationTree<UserDataState> = {
  [SET_NOTIFICATION](state: any, payload) {
    state.notification = payload.data;
  },
  [SET_TOKEN](state, token) {
    state.user.token = token;
    state.error = false;
    localStorage.setItem("SCPP_TOKEN", token);
  },
  [SET_TOKEN_ERROR](state) {
    state.user.token = null;
    state.user.profile = null;
    state.error = true;
    localStorage.removeItem("SCPP_TOKEN");
  },
  [SET_PROFILE_DATA](state, data) {
    state.user.profile = data;
  },
  [CLEAR_PROFILE_DATA](state) {
    state.user.token = null;
    state.user.profile = null;
    state.error = false;
    localStorage.removeItem("SCPP_TOKEN");
  }
};

const userDataStore: Module<UserDataState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state
};

export default userDataStore;
