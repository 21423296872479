import {
  VIEW_COURSE_PERM,
  VIEW_COURSE_MANUAL_PERM,
  VIEW_FEEDBACK_QUESTION_PERM,
  VIEW_MASTER_REPORT_PERM,
  VIEW_ADMIN_LOG_ENTRY_PERM,
  VIEW_PACKAGE_PERM,
  VIEW_AGREEMENT_PERM,
  CREATE_AGREEMENT_PERM,
  VIEW_ORGANIZATION_PERM,
  CREATE_ORGANIZATION_PERM,
  VIEW_FACTORY_PERM,
  CREATE_FACTORY_PERM,
  VIEW_PROFILE_PERM,
  VIEW_TRAINING_ACTION_PERM,
  EDIT_PROFILE_PERM,
  VIEW_USER_INVITATION_PERM,
  CREATE_FAQ_PERM,
  VIEW_FAQ_PERM,
  VIEW_FAQ_CATEGORY_PERM,
  CREATE_ORGANIZATION_BILLING_PERM,
  VIEW_ORGANIZATION_BILLING_PERM,
  FACTORY_VISIT_STATS_PERM,
  VIEW_FACTORY_VISIT_PERM,
  MANAGE_OWN_FACTORY_VISITS_PERM,
  VIEW_VISIT_REPORTS_EXPORT_PERM,
  VIEW_GROUP_PERM,
  CAN_VIEW_USER_PERMISSIONS_PERM,
  VIEW_ENROLLMENT_PERM
} from "./../utils/permission.names";
import { RouteConfig } from "vue-router";
import { UserRoles } from "../types/common.types";
import { hasAnyPerm, hasPerm, hasPerms } from "@/utils/user";

const routes: Array<RouteConfig> = [
  {
    path: "/dashboard",
    component: () => import("../layouts/MainApp.vue"),
    name: "admin-dashboard",
    // redirect: { name: "admin-courses" },
    // meta: {
    //   accessLevel: 0,
    // },
    children: [
      {
        path: "courses/pages/:page",
        name: "admin-courses-page",
        component: () => import("../views/dashboard/Courses.vue"),
        meta: {
          accessLevel: (p: any, to: any) => {
            return hasPerm(p.user, VIEW_COURSE_PERM);
          }
        }
      },
      {
        path: "courses/packages/",
        name: "packages",
        component: () => import("../views/dashboard/Packages.vue"),
        meta: {
          accessLevel: (p: any, to: any) => {
            return hasPerm(p.user, VIEW_PACKAGE_PERM);
          }
        }
      },
      {
        path: "courses/:slug",
        name: "admin-course-detail",
        component: () => import("../views/dashboard/CourseDetails.vue"),
        meta: {
          accessLevel: (p: any, to: any) => {
            return hasPerm(p.user, VIEW_COURSE_PERM);
          }
        }
      },
      {
        path: "courses/:slug/manuals/",
        name: "admin-course-manuals",
        component: () => import("../views/dashboard/CourseManuals.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_COURSE_MANUAL_PERM)
        }
      },

      {
        path: "courses/:slug/question/",
        name: "admin-course-question",
        component: () => import("../views/dashboard/CourseQuestion.vue"),
        meta: {
          accessLevel: UserRoles.INSTRUCTOR
        }
      },
      {
        path: "courses/:slug/feedback-question/",
        name: "admin-course-feedback-question",
        component: () =>
          import("../views/dashboard/CourseFeedbackQuestion.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_FEEDBACK_QUESTION_PERM)
        }
      },
      {
        path: "courses",
        name: "admin-courses",
        component: () => import("../views/dashboard/Courses.vue"),
        meta: {
          accessLevel: (p: any, to: any) => {
            return hasPerm(p.user, VIEW_COURSE_PERM);
          }
        }
      },
      {
        path: "enrollments/:id/",
        name: "admin-enrollment-details",
        component: () =>
          import("../views/dashboard/enrollment/EnrollmentDetails.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_ENROLLMENT_PERM)
        }
      },
      {
        path: "agreements/create/",
        name: "admin-agreements-create",
        component: () =>
          import("../views/dashboard/agreement/AgreementCreate.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, CREATE_AGREEMENT_PERM)
        }
      },
      {
        path: "agreements/:id/",
        name: "admin-agreement-details",
        component: () =>
          import("../views/dashboard/agreement/AgreementDetails.vue"),
        meta: {
          ccessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_AGREEMENT_PERM)
        }
      },
      {
        path: "agreements",
        name: "admin-agreements",
        component: () =>
          import("../views/dashboard/agreement/AgreementIndex.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_AGREEMENT_PERM)
        }
      },
      {
        path: "status/pages/",
        name: "admin-status-page",
        component: () => import("../views/dashboard/Status.vue"),
        meta: {
          accessLevel: UserRoles.AUDITOR
        }
      },
      {
        path: "action-plans/",
        name: "admin-action-plans",
        component: () =>
          import("../views/dashboard/AdminTrainingActionPlans.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_TRAINING_ACTION_PERM)
        }
      },
      {
        path: "organizations/create",
        name: "admin-organization-create",
        component: () => import("../views/dashboard/CreateOrganization.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, CREATE_ORGANIZATION_PERM)
        }
      },
      {
        path: "organizations",
        name: "admin-organization",
        component: () => import("../views/dashboard/Organization.vue"),
        meta: {
          accessLevel: (profile: any) => {
            console.log(profile);
            return (
              hasPerm(profile.user, VIEW_ORGANIZATION_PERM) ||
              !!profile.user.factory_access.find((f: any) => f.is_focal)
            );
          }
        }
      },
      {
        path: "organizations/pages/:page",
        name: "admin-organizations-page",
        component: () => import("../views/dashboard/Organization.vue"),
        meta: {
          accessLevel: (profile: any) => {
            return (
              hasPerm(profile.user, VIEW_ORGANIZATION_PERM) ||
              !!profile.user.factory_access.find((f: any) => f.is_focal)
            );
          }
        }
      },
      {
        path: "organizations/:slug",
        name: "admin-organization-profile",
        component: () => import("../views/dashboard/OrganizationProfile.vue"),
        meta: {
          accessLevel: (profile: any) => {
            return (
              hasPerm(profile.user, VIEW_ORGANIZATION_PERM) ||
              !!profile.user.factory_access.find((f: any) => f.is_focal)
            );
          }
        }
      },
      {
        path: "organizations/:slug/factories/create",
        name: "factory-create",
        component: () => import("../views/dashboard/CreateFactory.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, CREATE_FACTORY_PERM)
        }
      },
      {
        path: "organizations/:slug/factories/:fslug",
        name: "factory-profile",
        component: () => import("../views/dashboard/FactoryProfile.vue"),
        meta: {
          accessLevel: (profile: any, to: any) => {
            return (
              hasPerm(profile.user, VIEW_FACTORY_PERM) ||
              !!profile.user.factory_access.find(
                (f: any) => f.is_focal && f.factory.slug == to.params.fslug
              )
            );
          }
        }
      },
      {
        path: "organizations/:slug/factories/:fslug/action-plans",
        name: "factory-action-plans",
        component: () =>
          import("../views/dashboard/FactoryTrainingActionPlans.vue"),
        meta: {
          accessLevel: (profile: any, to: any) => {
            return (
              hasPerm(profile.user, VIEW_FACTORY_PERM) ||
              !!profile.user.factory_access.find(
                (f: any) => f.is_focal && f.factory.slug == to.params.fslug
              )
            );
          }
        }
      },
      {
        path: "organizations/:slug/factories/:fslug/visits",
        name: "factory-visits",
        component: () => import("../views/dashboard/visits/FactoryVisits.vue"),
        meta: {
          accessLevel: (profile: any, to: any) => {
            return hasPerm(profile.user, VIEW_FACTORY_VISIT_PERM);
            // ||
            // !!profile.user.factory_access.find(
            //   (f: any) => f.is_focal && f.factory.slug == to.params.fslug
            // )
          }
        }
      },

      {
        path: "users/pages/:page",
        name: "admin-users-page",
        component: () => import("../views/dashboard/accessControl/Users.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_PROFILE_PERM)
        }
      },

      {
        path: "users/:slug/edit",
        name: "admin-users-edit",
        component: () =>
          import("../views/dashboard/accessControl/UserProfileEdit.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerms(p.user, [EDIT_PROFILE_PERM, VIEW_PROFILE_PERM])
        }
      },

      {
        path: "users/:slug/permissions",
        name: "admin-user-permissions",
        component: () =>
          import("../views/dashboard/accessControl/UserPermissions.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerms(p.user, [CAN_VIEW_USER_PERMISSIONS_PERM, CAN_VIEW_USER_PERMISSIONS_PERM])
        }
      },

      {
        path: "users/:slug",
        name: "admin-users-profile",
        component: () =>
          import("../views/dashboard/accessControl/UsersProfile.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_PROFILE_PERM)
        }
      },

      {
        path: "users",
        name: "admin-users",
        component: () => import("../views/dashboard/accessControl/Users.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_PROFILE_PERM)
        }
      },
      {
        path: "invitations",
        name: "admin-invitations",
        component: () =>
          import("../views/dashboard/accessControl/Invitations.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_USER_INVITATION_PERM)
        }
      },
      {
        path: "enrollments/pages/:page",
        name: "admin-enrollments",
        component: () => import("../views/dashboard/Enrollments.vue"),
        meta: {
          accessLevel: UserRoles.ADMIN
        }
      },

      {
        path: "access-log/pages/:page",
        name: "admin-access-log-page",
        component: () => import("../views/dashboard/AccessLog.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_ADMIN_LOG_ENTRY_PERM)
        }
      },

      {
        path: "access-log",
        name: "admin-access-log",
        component: () => import("../views/dashboard/AccessLog.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_ADMIN_LOG_ENTRY_PERM)
        }
      },
      {
        path: "messages",
        name: "admin-message",
        component: () => import("../views/dashboard/Message.vue"),
        meta: {
          accessLevel: UserRoles.ADMIN
        }
      },
      {
        path: "billing/create",
        name: "create-billing",
        component: () => import("../views/dashboard/CreateBilling.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, CREATE_ORGANIZATION_BILLING_PERM)
        }
      },

      {
        path: "billing/:id",
        name: "admin-billing-details",
        component: () => import("../views/dashboard/BillingDetails.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_ORGANIZATION_BILLING_PERM)
        }
      },

      {
        path: "billing/pages/:page",
        name: "admin-billing-page",
        component: () => import("../views/dashboard/Billing.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_ORGANIZATION_BILLING_PERM)
        }
      },

      {
        path: "billing",
        name: "admin-billing",
        component: () => import("../views/dashboard/Billing.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_ORGANIZATION_BILLING_PERM)
        }
      },

      {
        path: "support/faq/create",
        name: "create-faq",
        component: () => import("../views/dashboard/CreateFaq.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, CREATE_FAQ_PERM)
        }
      },
      // {
      //   path: "support/categories",
      //   name: "admin-support-faq-categories",
      //   component: () => import("../views/dashboard/FaqCategory.vue"),
      //   meta: {
      //     accessLevel: (p: any, to: any) =>
      //       hasPerm(p.user, VIEW_FAQ_CATEGORY_PERM)
      //   }
      // },

      {
        path: "support/faq",
        name: "admin-support-faq",
        component: () => import("../views/dashboard/Faq.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_FAQ_PERM)
        }
      },
      {
        path: "master-reports",
        name: "admin-master-reports",
        component: () =>
          import("../views/dashboard/AllOrganizationReports.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_MASTER_REPORT_PERM)
        }
      },
      {
        path: "visits/all",
        name: "admin-visits-all",
        component: () => import("../views/dashboard/visits/AllVisits.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasAnyPerm(p.user, [
              VIEW_FACTORY_VISIT_PERM,
              MANAGE_OWN_FACTORY_VISITS_PERM
            ])
        }
      },
      {
        path: "visits/factories",
        name: "admin-visits-factories",
        component: () =>
          import("../views/dashboard/visits/VisitByAgreement.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_FACTORY_VISIT_PERM)
        }
      },
      {
        path: "visits/reports",
        name: "admin-visit-exports",
        component: () => import("../views/dashboard/visits/VisitReports.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, VIEW_VISIT_REPORTS_EXPORT_PERM)
        }
      },
      {
        path: "visits",
        name: "admin-visits-overview",
        component: () => import("../views/dashboard/visits/VisitIndex.vue"),
        meta: {
          accessLevel: (p: any, to: any) =>
            hasPerm(p.user, FACTORY_VISIT_STATS_PERM)
        }
      },
      {
        path: "groups/create",
        name: "admin-group-create",
        component: () =>
          import("../views/dashboard/accessControl/GroupCreate.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_GROUP_PERM)
        }
      },
      {
        path: "groups/:id",
        name: "admin-group-details",
        component: () =>
          import("../views/dashboard/accessControl/GroupDetails.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_GROUP_PERM)
        }
      },
      {
        path: "groups",
        name: "admin-groups",
        component: () => import("../views/dashboard/accessControl/Groups.vue"),
        meta: {
          accessLevel: (p: any, to: any) => hasPerm(p.user, VIEW_GROUP_PERM)
        }
      }
      // {
      //   path: "",
      //   name: "admin-overview",
      //   // component: () => import("../views/dashboard/Overview.vue"),
      //   meta: {
      //     accessLevel: LOGIN_REQUIRED
      //   },
      //   // redirect(to) {
      //   //   if ()
      //   // },
      // },
    ]
  }
];

export default routes;
