import { ORDER_FAQS } from './../../action.names';
import axios from "axios";
import { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { AdminSupportState, RootState } from "../../../types/store.types";

import { ADMIN_LOGS_URL, PUBLIC_FAQ_URL } from "@/store/endpoints";

import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";
import {
  DELETE_FAQ,
  CREATE_OR_UPDATE_FAQ,
  GET_LOGS,
  GET_FAQ
} from "../../action.names";

import { buildParams, namespaced } from "../../utils";

const state: AdminSupportState = {};

const getters: GetterTree<AdminSupportState, RootState> = {};
const actions: ActionTree<AdminSupportState, RootState> = {
  async [DELETE_FAQ]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${PUBLIC_FAQ_URL}/${id}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [GET_FAQ]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PUBLIC_FAQ_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_UPDATE_FAQ]({ rootGetters }, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${PUBLIC_FAQ_URL}/`;
      const id = formData.id || null;
      if (id != null) {
        delete formData.id;
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [ORDER_FAQS]({ rootGetters }, ids: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${PUBLIC_FAQ_URL}/order/`,
          ids,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [GET_LOGS](
    { rootGetters },
    filters: { [key: string]: any } = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_LOGS_URL}/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<AdminSupportState> = {};

const adminSupportModule: Module<AdminSupportState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};

export default adminSupportModule;
