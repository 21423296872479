import axios, { AxiosError } from "axios";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";

import { RootState } from "@/types/store.types";
import { buildParams, namespaced } from "@/store/utils";
import { NS_USER } from "@/store/namespace.names";
import {
  ADMIN_API,
  ADMIN_ENROLLMENT_ENDPOINT,
  ADMIN_TRAINING_SUBGROUPS_URL,
  INVITATION_URL,
  PUBLIC_API,
  TEMPLATE_URL
} from "@/store/endpoints";
import { AUTH_HEADER, GET_COURSE_FILES } from "@/store/getter.names";
import {
  TEMPLATE_FILE_DELETE,
  TEMPLATE_FILE_UPLOAD,
  TEMPLATE_FILE,
  TEMPLATE_CREATE_OR_UPDATE,
  TEMPLATE,
  TRAINING_USER_UPDATE,
  TRAINING_SESSION_CREATE_OR_UPDATE,
  TRAINING_SESSION_DELETE,
  TRAINING_SESSION,
  TRAINING_SESSION_FILE_UPLOAD,
  TRAINING_SESSION_FILES,
  TRAINING_SESSION_FILE_DELETE,
  TRAINING_ACTION_PLAN_CREATE_OR_UPDATE,
  GET_ACTION_PLANS,
  REQUEST_TRAINING_ACTIONS_REPORT,
  TRAINING_ACTION_BULK_UPLOAD,
  DELETE_ACTION_PLAN,
  FETCH_TRAINING_SUBGROUPS,
  ADD_OR_EDIT_TRAINING_SUBGROUPS,
  DELETE_TRAINING_SUBGROUP,
  GET_USER_INVITATIONS,
  FORCE_ACTIVATE_TRAINING,
  DELETE_USER_INVITATION,
  RESEND_INVITATION,
  PUBLIC_GET_ACTION_PLANS,
  PUBLIC_TRAINING_ACTION_PLAN_CREATE_OR_UPDATE
} from "@/store/action.names";
import { SET_COURSE_FILES } from "@/store/mutation.names";
import { KeyObj, ApiPaginationType } from "@/types/common.types";
import type { Api } from "@/types/api.types";

const state: KeyObj = {
  courseFile: {}
};

const getters: GetterTree<KeyObj, RootState> = {
  [GET_COURSE_FILES]: (state) => (url: string) => state.courseFile[url]
};

const actions: ActionTree<KeyObj, RootState> = {
  async [TRAINING_USER_UPDATE](
    { rootGetters },
    { payload, trainingID, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ADMIN_API}/training/${trainingID}/users/${id}/`, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TEMPLATE]({ rootGetters }, { filter }): Promise<ApiPaginationType> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${TEMPLATE_URL}${buildParams(filter)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TEMPLATE_CREATE_OR_UPDATE](
    { rootGetters },
    { payload, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = TEMPLATE_URL;
      if (id) {
        url += `${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TEMPLATE_FILE](
    { rootGetters, getters, commit },
    { templateId, params }
  ): Promise<ApiPaginationType> {
    return new Promise((resolve, reject) => {
      // console.log(templateId, params);
      const url = `${TEMPLATE_URL}${templateId}/file/${buildParams(params)}`;
      const files = getters[GET_COURSE_FILES](url);
      if (files) {
        resolve(files);
      } else {
        axios
          .get(url, {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          })
          .then(({ data }) => {
            commit(SET_COURSE_FILES, { data, url });
            resolve(data);
          })
          .catch((e: AxiosError) => {
            reject(e);
          });
      }
    });
  },
  async [TEMPLATE_FILE_UPLOAD](
    { rootGetters },
    { payload, onProgress, templateId }: KeyObj
  ): Promise<Node | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${TEMPLATE_URL}${templateId}/file/`, payload, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          },
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data as Node);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TEMPLATE_FILE_DELETE](
    { rootGetters },
    { templateId, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${TEMPLATE_URL}${templateId}/file/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },

  async [TRAINING_SESSION]({ rootGetters }, { params }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_API}/training-session/${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [REQUEST_TRAINING_ACTIONS_REPORT](
    { rootGetters },
    { filters }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_API}/action-plans/email_report/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_ACTION_PLANS]({ rootGetters }, { filters }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_API}/action-plans/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_ACTION_PLAN]({ rootGetters }, id): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      const url = `${ADMIN_API}/action-plans/${id}`;
      axios
        .delete(url, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_ACTION_PLAN_CREATE_OR_UPDATE](
    { rootGetters },
    { payload, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_API}/action-plans/`;
      if (id) {
        url += `${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [PUBLIC_GET_ACTION_PLANS]({ rootGetters }, { filters }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PUBLIC_API}/action-plans/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [PUBLIC_TRAINING_ACTION_PLAN_CREATE_OR_UPDATE](
    { rootGetters },
    { payload, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${PUBLIC_API}/action-plans/`;
      if (id) {
        url += `${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_ACTION_BULK_UPLOAD](
    { rootGetters },
    payload
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_API}/action-plans-upload-task/`, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_CREATE_OR_UPDATE](
    { rootGetters },
    { payload, id }: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_API}/training-session/`;
      if (id !== undefined) {
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_DELETE]({ rootGetters }, { id }): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_API}/training-session/${id}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILE_UPLOAD](
    { rootGetters },
    { payload, onProgress, trainingId }: any
  ): Promise<Node | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_API}/training-session/${trainingId}/file/`, payload, {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              onProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          },
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data as Node);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILES](
    { rootGetters },
    { trainingId, params }
  ): Promise<ApiPaginationType> {
    return new Promise((resolve, reject) => {
      // console.log(trainingId, params);
      axios
        .get(
          `${ADMIN_API}/training-session/${trainingId}/file/${buildParams(
            params
          )}`,
          { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] } }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILE_DELETE](
    { rootGetters },
    { trainingId, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_API}/training-session/${trainingId}/file/${id}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_TRAINING_SUBGROUPS]({ rootGetters }, parameters): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_TRAINING_SUBGROUPS_URL}/${buildParams(parameters)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADD_OR_EDIT_TRAINING_SUBGROUPS](
    { rootGetters },
    formData
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${ADMIN_TRAINING_SUBGROUPS_URL}/`;
      if (formData.id !== undefined) {
        url = `${url}${formData.id}/`;
        method = axios.patch;
        delete formData.id;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_TRAINING_SUBGROUP]({ rootGetters }, id): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_TRAINING_SUBGROUPS_URL}/${id}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_USER_INVITATIONS](
    { rootGetters },
    filter: Api.PublicUserInviteList.RequestQuery
  ): Promise<Api.PublicUserInviteList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${INVITATION_URL}/${buildParams(filter)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [DELETE_USER_INVITATION]({ rootGetters }, code): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${INVITATION_URL}/${code}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [RESEND_INVITATION]({ rootGetters }, code): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${INVITATION_URL}/${code}/resend_invitation/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [FORCE_ACTIVATE_TRAINING](
    { rootGetters },
    params: { id: number }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_ENROLLMENT_ENDPOINT}/${params.id}/force_activate/`,
          {},
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<KeyObj> = {
  [SET_COURSE_FILES](state, data) {
    state.courseFile[data.url] = data.data;
  }
};

const adminTrainingData: Module<KeyObj, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state
};

export default adminTrainingData;
