import axios from "axios";
import { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { CmsDataState, RootState } from "../../types/store.types";
import { buildParams } from "../utils";

import { SETTINGS_URL, PAGE_URL } from "../endpoints";
import { SETTINGS } from "../getter.names";
import { FETCH_SETTINGS, FETCH_PAGE_DATA } from "../action.names";
import { SET_SETTINGS } from "../mutation.names";

const namespaced = true;

const state: CmsDataState = {
  settings: {
    menus: {
      main_menus: [],
      flat_menus: []
    },
    footer: {
      id: 0,
      text: null,
      phone: null,
      email: null,
      copyright: null
    },
    social: {
      id: 0,
      youtube_url: null,
      facebook_url: null,
      twitter_url: null
    }
  },
  error: false
};

const getters: GetterTree<CmsDataState, RootState> = {
  [SETTINGS](state): any {
    return state.settings;
  }
};

const actions: ActionTree<CmsDataState, RootState> = {
  async [FETCH_SETTINGS]({ commit, dispatch }): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log(loginData);
      axios
        .get(SETTINGS_URL)
        .then(({ data }) => {
          commit(SET_SETTINGS, data);
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_PAGE_DATA]({ commit, dispatch }, path): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PAGE_URL}/find/?html_path=${path}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<CmsDataState> = {
  [SET_SETTINGS](state, data) {
    state.settings = data;
  }
};

const userDataStore: Module<CmsDataState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state
};

export default userDataStore;
