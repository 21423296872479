export const HOST: string =
  process.env.VUE_APP_DEVHOST || "http://localhost:8000";

export const API_ROOT = `${HOST}/api/v1`;
export const PUBLIC_API = `${API_ROOT}/public`;
export const ADMIN_API = `${API_ROOT}/admin`;
export const PUBLIC_EXAM = `${PUBLIC_API}/exam`;
export const PUBLIC_LESSON = `${PUBLIC_API}/lesson`;
export const NOTIFICATIONS_URL = `${PUBLIC_API}/notifications`;
export const SCHEDULE_URL = `${PUBLIC_API}/schedule`;
export const PRESENTATION_URL = `${PUBLIC_API}/presentation/courses`;
export const REVIEWABLE_URL = `${PUBLIC_API}/review/courses`;

/*
  ####   #    #   ####
 #    #  ##  ##  #
 #       # ## #   ####
 #       #    #       #
 #    #  #    #  #    #
  ####   #    #   ####
*/
export const CMS_URL = `${HOST}/api/v2`;
export const SETTINGS_URL = `${CMS_URL}/settings/`;
export const PAGE_URL = `${CMS_URL}/pages`;

/*
  _    _
 | |  | |
 | |  | |___  ___ _ __
 | |  | / __|/ _ \ '__|
 | |__| \__ \  __/ |
  \____/|___/\___|_|
*/
export const AUTH_API_ROOT = `${API_ROOT}/auth`;
export const LOGIN_URL = `${AUTH_API_ROOT}/login`;
export const LOGOUT_URL = `${AUTH_API_ROOT}/logout`;
export const INVITATION_URL = `${API_ROOT}/public/user/invite`;
export const PROFILE_URL = `${PUBLIC_API}/profile`;
export const USER_PASSWORD = `${PUBLIC_API}/password`;
export const COURSES_ENDPOINT = `${PUBLIC_API}/courses`;
export const TRAINING_SESSION_EVENT_ENDPOINT = `${PUBLIC_API}/events/calender/t/training-session`;
export const FETCH_EVENTS_ENDPOINT = `${PUBLIC_API}/events/calender`;
export const ENROLLMENT_ENDPOINT = `${PUBLIC_API}/enrollments`;
export const ADMIN_USERS_URL = `${ADMIN_API}/users`;
export const ADMIN_STATS_URL = `${ADMIN_API}/dashboard-data/`;
export const INVITE_USER_URL = `${PUBLIC_API}/user/invite/invite`;
export const INVITE_BATCH_USER_URL = `${PUBLIC_API}/user/invite/batch_invite`;
export const BATCH_USER_ASSIGN_URL = `${PUBLIC_API}/user/invite/batch_user_assign`;
export const XLSX_TEMPLATE_URL = `${ADMIN_API}/xlsx-template`;
export const DOWNLOAD_TRAINING_SESSION_ENDPOINT = `${API_ROOT}/download/training-session`;

/*
              _           _
     /\      | |         (_)
    /  \   __| |_ __ ___  _ _ __
   / /\ \ / _` | '_ ` _ \| | '_ \
  / ____ \ (_| | | | | | | | | | |
 /_/    \_\__,_|_| |_| |_|_|_| |_|
*/

export const ADMIN_COURSES_URL = `${ADMIN_API}/courses`;
export const ADMIN_PACKAGES_URL = `${ADMIN_API}/packages`;
export const ADMIN_USERS_COURSES = `${ADMIN_API}/enrollments`;
export const ADMIN_ENROLLMENT_ENDPOINT = `${ADMIN_API}/enrollments`;
export const TRAINING_SESSION_ENDPOINT = `${ADMIN_API}/training-session`;
export const PUBLIC_TRAINING_SESSION_ENDPOINT = `${PUBLIC_API}/training-session`;
export const ADMIN_AGREEMENT_ENDPOINT = `${ADMIN_API}/agreements`;
export const ADMIN_VISIT_AGREEMENTS_ENDPOINT = `${ADMIN_API}/visit-agreements`;
export const ADMIN_GROUP_ENDPOINT = `${ADMIN_API}/groups`;
/*
  __  __          _ _
 |  \/  |        | (_)
 | \  / | ___  __| |_  __ _
 | |\/| |/ _ \/ _` | |/ _` |
 | |  | |  __/ (_| | | (_| |
 |_|  |_|\___|\__,_|_|\__,_|
*/
export const MEDIABROWSER_API = `${HOST}/mediabrowser`;
export const MEDIA_API_URL = `${MEDIABROWSER_API}/media`;

/*
   ____                        _          _   _
  / __ \                      (_)        | | (_)
 | |  | |_ __ __ _  __ _ _ __  _ ______ _| |_ _  ___  _ __  ___
 | |  | | '__/ _` |/ _` | '_ \| |_  / _` | __| |/ _ \| '_ \/ __|
 | |__| | | | (_| | (_| | | | | |/ / (_| | |_| | (_) | | | \__ \
  \____/|_|  \__, |\__,_|_| |_|_/___\__,_|\__|_|\___/|_| |_|___/
              __/ |
             |___/
*/

export const ADMIN_ORGANIZATIONS_URL = `${ADMIN_API}/organizations`;
export const ADMIN_FACTORIES_URL = `${ADMIN_API}/factories`;
export const ADMIN_ORGANIZATION_REPORT = `${ADMIN_API}/reports/organization`;
export const ADMIN_ALL_ORGANIZATION_REPORT = `${ADMIN_API}/reports/all-organizations`;
export const ADMIN_VISIT_REPORTS_EXPORTS = `${ADMIN_API}/reports/visit-reports-exports`;
export const ADMIN_FACTORY_VISITS_URL = `${ADMIN_API}/factory-visits`;
export const ADMIN_FACTORY_VISIT_STATS_URL = `${ADMIN_API}/visit-tracker-stats/`;
/*
    ____            _             _     ____
   / ___|___  _ __ | |_ __ _  ___| |_  |  _ \ ___ _ __ ___  ___  _ __
  | |   / _ \| '_ \| __/ _` |/ __| __| | |_) / _ \ '__/ __|/ _ \| '_ \
  | |__| (_) | | | | || (_| | (__| |_  |  __/  __/ |  \__ \ (_) | | | |
   \____\___/|_| |_|\__\__,_|\___|\__| |_|   \___|_|  |___/\___/|_| |_|
*/

export const ADMIN_CONTACT_PERSON_URL = `${ADMIN_API}/contact-person`;

/*
  _______        _       _
 |__   __|      (_)     (_)
    | |_ __ __ _ _ _ __  _ _ __   __ _
    | | '__/ _` | | '_ \| | '_ \ / _` |
    | | | | (_| | | | | | | | | | (_| |
    |_|_|  \__,_|_|_| |_|_|_| |_|\__, |
                                  __/ |
                                 |___/
*/

export const ADMIN_TRAININGS_URL = `${ADMIN_API}/training`;
export const ADMIN_TRAINING_SUBGROUPS_URL = `${ADMIN_API}/training-sub-groups`;
export const TRAINER_CERTIFICATE = `${PUBLIC_API}/trainer-certificates`;

/*
                                    _   
   ___ _   _ _ __  _ __   ___  _ __| |_ 
  / __| | | | '_ \| '_ \ / _ \| '__| __|
  \__ \ |_| | |_) | |_) | (_) | |  | |_ 
  |___/\__,_| .__/| .__/ \___/|_|   \__|
            |_|   |_|                   
*/

export const PUBLIC_FAQ_URL = `${PUBLIC_API}/faq`;

/*
   _                    
  | |    ___   __ _ ___ 
  | |   / _ \ / _` / __|
  | |__| (_) | (_| \__ \
  |_____\___/ \__, |___/
              |___/     
*/

export const ADMIN_LOGS_URL = `${ADMIN_API}/customlog`;

/*
   _     _ _ _ _             
  | |__ (_) | (_)_ __   __ _ 
  | '_ \| | | | | '_ \ / _` |
  | |_) | | | | | | | | (_| |
  |_.__/|_|_|_|_|_| |_|\__, |
                       |___/ 
*/

export const ADMIN_BILLING_URL = `${ADMIN_API}/billing`;

/*
 #    #  ######   ####    ####     ##     ####   ######
 ##  ##  #       #       #        #  #   #    #  #
 # ## #  #####    ####    ####   #    #  #       #####
 #    #  #            #       #  ######  #  ###  #
 #    #  #       #    #  #    #  #    #  #    #  #
 #    #  ######   ####    ####   #    #   ####   ######
*/

export const ROOM_URL = `${PUBLIC_API}/support/room/`;
export const CREATE_ROOM_URL = `${PUBLIC_API}/support/room/create_room/`;
export const USER_INFO_URL = (code: string) =>
  `${PUBLIC_API}/support/room/${code}/user_info/`;
export const MESSAGE_URL = (code: string) =>
  `${PUBLIC_API}/support/room/${code}/message/`;

export const TEMPLATE_URL = `${ADMIN_API}/template/`;
