import type { Api, PaginatedRoomList } from "./../../types/api.types";
import axios from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { MessageStoreState, RootState } from "../../types/store.types";

import {
  ROOM_URL,
  CREATE_ROOM_URL,
  MESSAGE_URL,
  USER_INFO_URL
} from "../endpoints";
import {
  AUTH_HEADER,
  GET_MESSAGE_ROOM,
  GET_MESSAGE_BY_ROOM,
  GET_ROOM_USER_INFO
} from "../getter.names";
import {
  CREATE_ROOM,
  FETCH_ROOMS,
  ROOM_USER_INFO,
  SEND_MESSAGE,
  FETCH_MESSAGE_BY_ROOM
} from "../action.names";
import {
  SET_MESSAGE_ROOM,
  SET_MESSAGE_BY_ROOM,
  SET_USER_ROOM_INFO
} from "../mutation.names";
import { NS_USER } from "@/store/namespace.names";
import { namespaced } from "@/store/utils";
import type { Room, Message, PublicUser } from "../../types/api.types";
import { buildParams } from "../utils";

const state: MessageStoreState = {
  message: {},
  userInfo: {},
  room: {
    results: [],
    is_next: false,
    is_previous: false
  },
  error: false
};

const getters: GetterTree<MessageStoreState, RootState> = {
  [GET_MESSAGE_ROOM]: state => () => state.room,
  [GET_MESSAGE_BY_ROOM]: state => (code: string) => state.message[code],
  [GET_ROOM_USER_INFO]: state => (code: string) => state.userInfo[code]
};

const actions: ActionTree<MessageStoreState, RootState> = {
  [FETCH_ROOMS](
    { commit, rootGetters, getters },
    params
  ): Promise<Api.PublicSupportRoomList.ResponseBody> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ROOM_URL}${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          const room: PaginatedRoomList = getters[GET_MESSAGE_ROOM]();
          room.results = [...data.results];
          (room as any).is_next = data.next ? true : false;
          commit(SET_MESSAGE_ROOM, room);
          resolve(room);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [CREATE_ROOM]({ rootGetters }, payload): Promise<Array<Room>> {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_ROOM_URL, payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data.results);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [ROOM_USER_INFO](
    { commit, rootGetters, getters },
    { code }
  ): Promise<PublicUser> {
    return new Promise((resolve, reject) => {
      const userInfo: PublicUser = getters[GET_ROOM_USER_INFO](code);
      if (userInfo) {
        resolve(userInfo);
      } else {
        axios
          .get(USER_INFO_URL(code), {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          })
          .then(({ data }) => {
            commit(SET_USER_ROOM_INFO, { code, data });
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  [FETCH_MESSAGE_BY_ROOM](
    { commit, rootGetters, getters },
    { code, readmore, refresh }
  ): Promise<Api.PublicSupportRoomMessageList.ResponseBody> {
    const limit = 10;
    return new Promise((resolve, reject) => {
      let page = 1;
      let messages: Array<Message> = [];
      const message = getters[GET_MESSAGE_BY_ROOM](code);
      if (!refresh && !readmore && message) {
        resolve(message);
      } else {
        if (message && !refresh) {
          messages = message.messages;
          if (message.page) page = message.page + 1;
        }
        const offset = (page - 1) * limit;
        axios
          .get(`${MESSAGE_URL(code)}${buildParams({ offset, limit })}`, {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          })
          .then(
            ({
              data
            }: {
              data: Api.PublicSupportRoomMessageList.ResponseBody;
            }) => {
              if (refresh) messages = [];
              if (data.results) {
                const message = {
                  page,
                  messages: [...messages, ...data.results]
                };
                commit(SET_MESSAGE_BY_ROOM, { code, message });
              }
              resolve(message);
            }
          )
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  [SEND_MESSAGE](
    { commit, rootGetters, getters },
    { code, payload }
  ): Promise<Message> {
    return new Promise((resolve, reject) => {
      axios
        .post(MESSAGE_URL(code), payload, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          const message = getters[GET_MESSAGE_BY_ROOM](code);
          message.messages = [data, ...message.messages];
          commit(SET_MESSAGE_BY_ROOM, { code, message });
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<MessageStoreState> = {
  [SET_MESSAGE_ROOM](state: any, payload) {
    state.room = payload;
  },
  [SET_MESSAGE_BY_ROOM](state: any, payload) {
    state.message[payload.code] = payload.message;
  },
  [SET_USER_ROOM_INFO](state: any, payload) {
    state.userInfo[payload.code] = payload.data;
  }
};

const userDataStore: Module<MessageStoreState, RootState> = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};

export default userDataStore;
