import { namespace } from "vuex-class";

export const NS_USER = "user";
export const NS_MESSAGE = "message";
export const NS_CMS = "cms";
export const NS_PUBLIC = "public";
export const NS_ADMIN = "admin";
export const NS_MEDIA = "media";

export const MediaModule = namespace(NS_MEDIA);
export const CmsModule = namespace(NS_CMS);
export const UserModule = namespace(NS_USER);
export const MessageModule = namespace(NS_MESSAGE);
export const PublicModule = namespace(NS_PUBLIC);
export const AdminModule = namespace(NS_ADMIN);
