export const SETTINGS = "settings";
/*
  _    _
 | |  | |
 | |  | |___  ___ _ __
 | |  | / __|/ _ \ '__|
 | |__| \__ \  __/ |
  \____/|___/\___|_|


*/
export const PROFILE = "user";
export const TOKEN = "authToken";
export const NOTIFICATION = "notification";
export const AUTH_HEADER = "authHeader";
export const GET_COURSE_BY_SLUG = "getCourseBySlug";
export const ENROLLMENTS = "enrollments";
export const ENROLLMENT = "enrollment";
export const COURSE_MODULES = "courseModules";
export const COURSE_SEARCH_RESULTS = "courseSearchResults";
export const IS_FOCAL = "isFocal";
export const HAS_PERM = "hasPerm";
export const HAS_PERMS = "hasPerms";
export const HAS_ANY_PERM = "hasAnyPerm";
export const FIND_FIRST_AVAILABLE_PERM = "findFirstAvailablePerm";
export const DASHBOARD_ACCESS = "dashboardAccess";
export const GET_PERMISSIONS_MAP = "getPermissionsMap";
export const IS_SUPERUSER = "isSuperuser";
/*
              _           _
     /\      | |         (_)
    /  \   __| |_ __ ___  _ _ __
   / /\ \ / _` | '_ ` _ \| | '_ \
  / ____ \ (_| | | | | | | | | | |
 /_/    \_\__,_|_| |_| |_|_|_| |_|


*/

export const COURSES = "courses";
export const BILLING_FULL_DETAILS = "billingFullDetails";

export const GET_MESSAGE_ROOM = "getMessageRoom";
export const GET_MESSAGE_BY_ROOM = "getMessageByRoom";
export const GET_ROOM_USER_INFO = "getRoomUserInfo";

export const GET_COURSE_FILES = "getCourseFiles";
