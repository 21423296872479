import { Api } from '@/types/api.types';
import axios from "axios";
import { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { PublicSupportState, RootState } from "../../../types/store.types";
import { PUBLIC_FAQ_URL } from "@/store/endpoints";

import { GET_FAQS } from "../../action.names";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";

import { namespaced } from "../../utils";

const state: PublicSupportState = {};

const getters: GetterTree<PublicSupportState, RootState> = {};
const actions: ActionTree<PublicSupportState, RootState> = {
  async [GET_FAQS]({ rootGetters }): Promise<Api.PublicFaqList.RequestBody> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PUBLIC_FAQ_URL}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<PublicSupportState> = {};

const publicSupportModule: Module<PublicSupportState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};

export default publicSupportModule;
