export enum UserRoles {
  ADMIN = 100,
  INSTRUCTOR = 90,
  AUDITOR = 80,
  ASSESSOR = 50,
  CLIENT = 20,
  BASIC = 0
}

export enum ContentType {
  EXAM = "exam",
  LESSON = "lesson",
  MODULE_STATUS = "module_status"
}

export interface KeyObj {
  [key: string]: any;
}

export interface ApiPaginationType {
  count: number;
  next: string | null;
  previous: string | null;
  results: KeyObj[];
}

export enum OrganizationReportSpecKind {
  PARTICIPATION = "participation",
  PARTICIPATION_BY_PACKAGE = "participation_by_package",
  DELIVERY = "delivery"
}
