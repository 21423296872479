/*
  ####   #    #   ####
 #    #  ##  ##  #
 #       # ## #   ####
 #       #    #       #
 #    #  #    #  #    #
  ####   #    #   ####
*/
export const SET_SETTINGS = "setSettings";

/*
  _    _
 | |  | |
 | |  | |___  ___ _ __
 | |  | / __|/ _ \ '__|
 | |__| \__ \  __/ |
  \____/|___/\___|_|


*/
export const SET_PROFILE_DATA = "setProfileData";
export const CLEAR_PROFILE_DATA = "clearProfileData";
export const SET_TOKEN = "setToken";
export const SET_NOTIFICATION = "setNotification";
export const SET_TOKEN_ERROR = "setTokenError";
export const SET_TOKEN_FROM_LOCAL_STORE = "setTokenFromLocalStore";

export const SET_COURSES = "appendCourses";
export const SET_COURSE_BY_SLUG = "setCourseBySlug";
export const SET_ENROLLMENTS = "setEnrollments";
export const SET_ENROLLMENT = "setEnrollment";
export const SET_COURSE_MODULES = "setCourseModules";
export const SET_COURSE_SEARCH_RESULTS = "setCourseSearchResults";

/*
              _           _
     /\      | |         (_)
    /  \   __| |_ __ ___  _ _ __
   / /\ \ / _` | '_ ` _ \| | '_ \
  / ____ \ (_| | | | | | | | | | |
 /_/    \_\__,_|_| |_| |_|_|_| |_|


*/
export const SET_COURSE_DATA = "setCourseData";
export const SET_BILLING_FULL_DATA = "setBillingFullData";

export const SET_MESSAGE_ROOM = "setMessageRoom";
export const SET_MESSAGE_BY_ROOM = "setMessageByRoom";
export const SET_USER_ROOM_INFO = "setUserRoomInfo";

export const SET_COURSE_FILES = "setCourseFiles";
