import { StatusOnlyResponse } from './../../../types/api.types';

import type { Api } from '@/types/api.types';
import { FETCH_ENROLLMENT_CONTENT_INDEX, GRANT_OR_REVOKE_TRAINERSHIP, MANIPULATE_PROGRESS, REGENERATE_CERTIFICATE_IMAGE, TRANSFER_ENROLLMENT_TO_AGREEMENT, UPDATE_ADMIN_RATING } from "./../../action.names";
import axios from "axios";
import { AxiosError } from "axios";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";

import { AdminEnrollmentsState, RootState } from "../../../types/store.types";

import {
  ADMIN_TRAININGS_URL,
  TRAINER_CERTIFICATE,
  ADMIN_COURSES_URL,
  ADMIN_ENROLLMENT_ENDPOINT,
  XLSX_TEMPLATE_URL,
  ENROLLMENT_ENDPOINT
} from "@/store/endpoints";

import {
  GET_TRAINING,
  GET_TRAININGS,
  TRAINING_FACTORY_CHANGE,
  DATE_WISE_REPORT,
  CREATE_OR_UPDATE_TRAINING,
  DELETE_TRAINING,
  ADD_USER_IN_TRAINING,
  GET_USERS_BY_TRAINING,
  TRAINING_PERMISSION,
  TRAINING_PERMISSION_UPDATE,
  COURSE_QUESTION,
  COURSE_QUESTION_GROUP,
  COURSE_QUESTION_ORDER,
  COURSE_QUESTION_GROUP_ORDER,
  FETCH_ENROLLMENT,
  FETCH_ENROLLMENTS,
  FETCH_ENROLLMENTS_QUESTION,
  ENROLLMENT_UPDATE,
  DELETE_USER_FROM_TRAINING,
  TRANSFER_TRAINING_ACTIONS,
  TRANSFER_ENROLLMENT
} from "../../action.names";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";

import { buildParams, METHODS_MAP, namespaced } from "@/store/utils";

const state: AdminEnrollmentsState = {};

const getters: GetterTree<AdminEnrollmentsState, RootState> = {};
const actions: ActionTree<AdminEnrollmentsState, RootState> = {
  [FETCH_ENROLLMENTS]({ rootGetters }, params: Api.AdminEnrollmentsList.RequestQuery = {}): Promise<Api.AdminEnrollmentsList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_ENROLLMENT_ENDPOINT}/${buildParams(params)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [FETCH_ENROLLMENT]({ rootGetters }, id: any): Promise<Api.AdminEnrollmentsList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_ENROLLMENT_ENDPOINT}/${id}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [MANIPULATE_PROGRESS]({ rootGetters }, {id, payload}: {id: any, payload: any}): Promise<Api.AdminEnrollmentsList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ADMIN_ENROLLMENT_ENDPOINT}/${id}/manipulate_progress/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [ENROLLMENT_UPDATE]({ rootGetters }, { payload, id }: {payload: Api.AdminEnrollmentsPartialUpdate.RequestBody | Api.AdminEnrollmentsUpdate.RequestBody, id: number}): Promise<Api.AdminEnrollmentsUpdate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ADMIN_ENROLLMENT_ENDPOINT}/${id}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [FETCH_ENROLLMENT_CONTENT_INDEX](
    {  rootGetters },
    { enrollmentID }
  ): Promise<any> {
    const { data } = await axios.get(`${ADMIN_ENROLLMENT_ENDPOINT}/${enrollmentID}/content_index/`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    })
    return data;
  },

  [TRANSFER_ENROLLMENT]({ rootGetters }, { id, to }: {id: number, to: number}): Promise<Api.AdminEnrollmentsTransferToTrainingCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ADMIN_ENROLLMENT_ENDPOINT}/${id}/transfer_to_training/`,
          { to },
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [FETCH_ENROLLMENTS_QUESTION](
    { rootGetters },
    { enrollmentId }
  ): Promise<Api.AdminEnrollmentsCollectedQuestionList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADMIN_ENROLLMENT_ENDPOINT}/${enrollmentId}/collected_question/`,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  
  


  async [GET_TRAINING]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_TRAININGS_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [DELETE_TRAINING]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ADMIN_TRAININGS_URL}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  // async [ADD_USER_IN_TRAINING]({ rootGetters }, id: number): Promise<any> {
  //   return new Promise((resolve, reject) => {

  //   })
  // }

  // {
  // training: "slug",
  // payload
  // }
  

  [TRAINING_PERMISSION]({ commit, rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${TRAINER_CERTIFICATE}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [TRAINING_PERMISSION_UPDATE](
    { commit, rootGetters },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const certificate_id = payload.certificate_id;
      delete payload.certificate_id;
      axios
        .patch(`${TRAINER_CERTIFICATE}/${certificate_id}/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [GRANT_OR_REVOKE_TRAINERSHIP](
    { rootGetters },
    data: { type: string; id: number }
  ) {
    const url = `${ADMIN_ENROLLMENT_ENDPOINT}/${data.id}/${data.type}_trainership/`;
    return new Promise((resolve, reject) => {
      axios
        .patch(url, {}, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [REGENERATE_CERTIFICATE_IMAGE](
    { rootGetters },
    id: number
  ) {
    const url = `${ADMIN_ENROLLMENT_ENDPOINT}/${id}/regenerate_image/`;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [GET_USERS_BY_TRAINING](
    { rootGetters },
    { id, filters }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADMIN_TRAININGS_URL}/${id}/users/${buildParams(filters)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [DELETE_USER_FROM_TRAINING](
    { rootGetters },
    { training, users }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ADMIN_TRAININGS_URL}/${training}/remove_user/`,
          { users },
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  [COURSE_QUESTION]({ rootGetters }, data: { slug: string, type: string, id?: number, payload?: Api.AdminCoursesOfflineQuestionCreate.RequestBody | Api.AdminCoursesOfflineQuestionUpdate.RequestBody | Api.AdminCoursesOfflineQuestionPartialUpdate.RequestBody}): Promise<Api.AdminCoursesOfflineQuestionCreate.ResponseBody | Api.AdminCoursesOfflineQuestionDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let url = `${ADMIN_COURSES_URL}/${data.slug}/offline/question/`;
      const type = data.type;
      const method = METHODS_MAP[type];
      if (method != "post") {
        url +=`${data.id}/`
      }
      axios({
        method,
        url,
        data: data.payload,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      }).then(({ data }) => {
        resolve(data);
      }).catch(e => {
        reject(e)
      });
    });
  },
  [COURSE_QUESTION_ORDER]({ rootGetters }, { payload, slug }: {slug: string, payload: Api.AdminCoursesOfflineQuestionOrderCreate.RequestBody}): Promise<Api.AdminCoursesOfflineQuestionOrderCreate.ResponseBody> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ADMIN_COURSES_URL}/${slug}/offline/question/order/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [COURSE_QUESTION_GROUP]({ rootGetters }, data: { slug: string, type: string, id?: number, payload?: Api.AdminCoursesOfflineQuestionGroupCreate.RequestBody | Api.AdminCoursesOfflineQuestionGroupUpdate.RequestBody | Api.AdminCoursesOfflineQuestionGroupPartialUpdate.RequestBody}): Promise<Api.AdminCoursesOfflineQuestionGroupCreate.ResponseBody | Api.AdminCoursesOfflineQuestionGroupDestroy.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      let url = `${ADMIN_COURSES_URL}/${data.slug}/offline/question-group/`;
      const type = data.type;
      const method = METHODS_MAP[type];
      if (method != "post") {
        url +=`${data.id}/`
      }
      axios({
        method,
        url,
        data: data.payload,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      }).then(({ data }) => {
        resolve(data);
      }).catch(e => {
        reject(e)
      });
    });
  },
  [COURSE_QUESTION_GROUP_ORDER]({ rootGetters }, data: {slug: string, payload: Api.AdminCoursesOfflineQuestionGroupCreate.RequestBody}): Promise<Api.AdminCoursesOfflineQuestionGroupCreate.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ADMIN_COURSES_URL}/${data.slug}/offline/question-group/order/`,
          data.payload,
          { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] } }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },


  async [UPDATE_ADMIN_RATING]({ rootGetters }, { enrollment_id, rating }): Promise<StatusOnlyResponse> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ADMIN_ENROLLMENT_ENDPOINT}/${enrollment_id}/update_admin_rating/`,
          {rating},
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    })
  },
  async [TRANSFER_ENROLLMENT_TO_AGREEMENT]({ rootGetters }, { enrollment, payload }){
    const { data } = await axios.patch(`${ADMIN_ENROLLMENT_ENDPOINT}/${enrollment}/transfer_to_agreement/`, payload, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
    return data;
  }
};
const mutations: MutationTree<AdminEnrollmentsState> = {};

const adminEnrollmentsModule: Module<AdminEnrollmentsState, RootState> = {
  namespaced: false,
  state,
  actions,
  getters,
  mutations
};

export default adminEnrollmentsModule;
