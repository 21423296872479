import { KeyObj } from "@/types/common.types";
import {
  ADD_OR_REMOVE_QUOTA,
  CREATE_OR_UPDATE_AGREEMENT,
  DELETE_AGREEMENT,
  ENROLL_USERS_TO_AGREEMENT,
  GET_AGREEMENT,
  GET_AGREEMENTS,
  GET_VISIT_AGREEMENTS,
  REMOVE_USERS_FROM_AGREEMENT,
  UPDATE_ENROLLMENT_ACTIVE_STATUS,
} from "@/store/action.names";
import {
  ADMIN_AGREEMENT_ENDPOINT,
  ADMIN_VISIT_AGREEMENTS_ENDPOINT,
} from "@/store/endpoints";
import { AUTH_HEADER } from "@/store/getter.names";
import { NS_USER } from "@/store/namespace.names";
import { buildParams, namespaced } from "@/store/utils";
import type { Api } from "@/types/api.types";
import { RootState } from "@/types/store.types";
import Axios, { AxiosError } from "axios";
import { ActionTree, Module } from "vuex";

const actions: ActionTree<any, RootState> = {
  async [GET_AGREEMENTS](
    { rootGetters },
    filters: Api.AdminAgreementsList.RequestQuery
  ): Promise<Api.AdminAgreementsList.ResponseBody | AxiosError> {
    const { data } = await Axios.get(
      `${ADMIN_AGREEMENT_ENDPOINT}/${buildParams(filters)}`,
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      }
    ).catch((error) => {
      throw error;
    });
    return data;
  },
  async [DELETE_AGREEMENT](
    { rootGetters },
    id: number
  ): Promise<Api.AdminAgreementsRetrieve.ResponseBody | AxiosError> {
    const { data } = await Axios.delete(`${ADMIN_AGREEMENT_ENDPOINT}/${id}/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
  async [GET_VISIT_AGREEMENTS](
    { rootGetters },
    filters: Api.AdminVisitAgreementsList.RequestQuery
  ): Promise<Api.AdminVisitAgreementsList.ResponseBody | AxiosError> {
    const { data } = await Axios.get(
      `${ADMIN_VISIT_AGREEMENTS_ENDPOINT}/${buildParams(filters)}`,
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      }
    ).catch((error) => {
      throw error;
    });
    return data;
  },
  async [GET_AGREEMENT](
    { rootGetters },
    id: number
  ): Promise<Api.AdminAgreementsRetrieve.ResponseBody | AxiosError> {
    const { data } = await Axios.get(`${ADMIN_AGREEMENT_ENDPOINT}/${id}/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
  async [CREATE_OR_UPDATE_AGREEMENT](
    { rootGetters },
    formData: Api.AdminAgreementsCreate.RequestBody
  ): Promise<
    | Api.AdminAgreementsCreate.ResponseBody
    | Api.AdminAgreementsRetrieve.ResponseBody
    | AxiosError
  > {
    let method = Axios.post;
    let url = `${ADMIN_AGREEMENT_ENDPOINT}/`;
    const id = formData.id || null;
    if (id != null) {
      url = `${url}${id}/`;
      method = Axios.patch;
    }
    const { data } = await method(url, formData, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
  async [ADD_OR_REMOVE_QUOTA](
    { rootGetters },
    formData: {
      agreement_id: number;
      action: "remove" | "add";
      payload: KeyObj;
    }
  ): Promise<KeyObj | AxiosError> {
    let url = `${ADMIN_AGREEMENT_ENDPOINT}/${formData.agreement_id}`;
    if (formData.action === "remove") {
      url = `${url}/remove_quota/`;
    } else {
      url = `${url}/add_quota/`;
    }
    const { data } = await Axios.patch(url, formData.payload, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },

  async [ENROLL_USERS_TO_AGREEMENT](
    { rootGetters },
    formData: {
      agreement_id: number;
      payload: KeyObj;
    }
  ): Promise<KeyObj | AxiosError> {
    const url = `${ADMIN_AGREEMENT_ENDPOINT}/${formData.agreement_id}/add_users/`;
    const { data } = await Axios.patch(url, formData.payload, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
  async [REMOVE_USERS_FROM_AGREEMENT](
    { rootGetters },
    formData: {
      agreement_id: number;
      payload: KeyObj;
    }
  ): Promise<KeyObj> {
    const url = `${ADMIN_AGREEMENT_ENDPOINT}/${formData.agreement_id}/remove_users/`;
    const { data } = await Axios.patch(url, formData.payload, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
  async [UPDATE_ENROLLMENT_ACTIVE_STATUS](
    { rootGetters },
    formData: {
      agreement_id: number;
      payload: KeyObj;
    }
  ): Promise<KeyObj> {
    const url = `${ADMIN_AGREEMENT_ENDPOINT}/${formData.agreement_id}/update_enrollment_active_status/`;
    const { data } = await Axios.patch(url, formData.payload, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
      },
    }).catch((error) => {
      throw error;
    });
    return data;
  },
};

const AgreementStore: Module<KeyObj, RootState> = {
  namespaced: false,
  actions,
};

export default AgreementStore;
