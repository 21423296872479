// Content Course
export const CREATE_COURSE_PERM = "content.add_course";
export const EDIT_COURSE_PERM = "content.change_course";
export const DELETE_COURSE_PERM = "content.delete_course";
export const VIEW_COURSE_PERM = "content.view_course";

// Content Package
export const CREATE_PACKAGE_PERM = "content.add_package";
export const EDIT_PACKAGE_PERM = "content.change_package";
export const DELETE_PACKAGE_PERM = "content.delete_package";
export const VIEW_PACKAGE_PERM = "content.view_package";

// Content CourseManuals
export const CREATE_COURSE_MANUAL_PERM = "content.add_coursemanual";
export const EDIT_COURSE_MANUAL_PERM = "content.change_coursemanual";
export const DELETE_COURSE_MANUAL_PERM = "content.delete_coursemanual";
export const VIEW_COURSE_MANUAL_PERM = "content.view_coursemanual";

// Content FeedbackQuestion
export const CREATE_FEEDBACK_QUESTION_PERM = "content.add_feedbackquestion";
export const EDIT_FEEDBACK_QUESTION_PERM = "content.change_feedbackquestion";
export const DELETE_FEEDBACK_QUESTION_PERM = "content.delete_feedbackquestion";
export const VIEW_FEEDBACK_QUESTION_PERM = "content.view_feedbackquestion";

// Content CourseFeedbackAnswer
export const CREATE_COURSE_FEEDBACK_ANSWER_PERM =
  "content.add_coursefeedbackanswer";
export const EDIT_COURSE_FEEDBACK_ANSWER_PERM =
  "content.change_coursefeedbackanswer";
export const DELETE_COURSE_FEEDBACK_ANSWER_PERM =
  "content.delete_coursefeedbackanswer";
export const VIEW_COURSE_FEEDBACK_ANSWER_PERM =
  "content.view_coursefeedbackanswer";

// Enrollment Template
export const CREATE_TEMPLATE_PERM = "enrollment.add_template";
export const EDIT_TEMPLATE_PERM = "enrollment.change_template";
export const DELETE_TEMPLATE_PERM = "enrollment.delete_template";
export const VIEW_TEMPLATE_PERM = "enrollment.view_template";

// Enrollment Agreement
export const CREATE_AGREEMENT_PERM = "enrollment.add_agreement";
export const EDIT_AGREEMENT_PERM = "enrollment.change_agreement";
export const DELETE_AGREEMENT_PERM = "enrollment.delete_agreement";
export const VIEW_AGREEMENT_PERM = "enrollment.view_agreement";

// Enrollment AllOrganizationReport
export const CREATE_MASTER_REPORT_PERM = "enrollment.add_allorganizationreport";
export const EDIT_MASTER_REPORT_PERM =
  "enrollment.change_allorganizationreport";
export const DELETE_MASTER_REPORT_PERM =
  "enrollment.delete_allorganizationreport";
export const VIEW_MASTER_REPORT_PERM = "enrollment.view_allorganizationreport";

// Enrollment OrganizationReport
export const CREATE_ORG_REPORT_PERM = "enrollment.add_organizationreport";
export const EDIT_ORG_REPORT_PERM = "enrollment.change_organizationreport";
export const DELETE_ORG_REPORT_PERM = "enrollment.delete_organizationreport";
export const VIEW_ORG_REPORT_PERM = "enrollment.view_organizationreport";

// Enrollment TrainingAction
export const CREATE_TRAINING_ACTION_PERM = "enrollment.add_trainingaction";
export const EDIT_TRAINING_ACTION_PERM = "enrollment.change_trainingaction";
export const DELETE_TRAINING_ACTION_PERM = "enrollment.delete_trainingaction";
export const VIEW_TRAINING_ACTION_PERM = "enrollment.view_trainingaction";
export const GENERATE_TRAINING_ACTION_REPORT_PERM =
  "enrollment.can_generate_training_action_report";

// Enrollment Enrollment
export const CREATE_ENROLLMENT_PERM = "enrollment.add_enrollment";
export const EDIT_ENROLLMENT_PERM = "enrollment.change_enrollment";
export const DELETE_ENROLLMENT_PERM = "enrollment.delete_enrollment";
export const VIEW_ENROLLMENT_PERM = "enrollment.view_enrollment";
export const CAN_MANIPULATE_ENROLLMENT_PROGRESS_PERM = "enrollment.can_manipulate_progress";

// Logs AdminLogEntry
export const CREATE_ADMIN_LOG_ENTRY_PERM = "logs.add_adminlogentry";
export const EDIT_ADMIN_LOG_ENTRY_PERM = "logs.change_adminlogentry";
export const DELETE_ADMIN_LOG_ENTRY_PERM = "logs.delete_adminlogentry";
export const VIEW_ADMIN_LOG_ENTRY_PERM = "logs.view_adminlogentry";

// Organizations Organization
export const CREATE_ORGANIZATION_PERM = "organizations.add_organization";
export const EDIT_ORGANIZATION_PERM = "organizations.change_organization";
export const DELETE_ORGANIZATION_PERM = "organizations.delete_organization";
export const VIEW_ORGANIZATION_PERM = "organizations.view_organization";

// Organizations Factory
export const CREATE_FACTORY_PERM = "organizations.add_factory";
export const EDIT_FACTORY_PERM = "organizations.change_factory";
export const DELETE_FACTORY_PERM = "organizations.delete_factory";
export const VIEW_FACTORY_PERM = "organizations.view_factory";
export const GENERATE_FACTORY_REPORT_PERM =
  "organizations.can_generate_factory_report";

// Organizations FactoryVisit
export const CREATE_FACTORY_VISIT_PERM = "organizations.add_factoryvisit";
export const EDIT_FACTORY_VISIT_PERM = "organizations.change_factoryvisit";
export const DELETE_FACTORY_VISIT_PERM = "organizations.delete_factoryvisit";
export const VIEW_FACTORY_VISIT_PERM = "organizations.view_factoryvisit";
export const FACTORY_VISIT_STATS_PERM =
  "organizations.can_view_factory_visit_stats";
export const MANAGE_OWN_FACTORY_VISITS_PERM = "organizations.manage_own_visits";

// Organizations FactoryVisitReport
export const CREATE_FACTORY_VISIT_REPORT_PERM =
  "organizations.add_factoryvisitreport";
export const EDIT_FACTORY_VISIT_REPORT_PERM =
  "organizations.change_factoryvisitreport";
export const DELETE_FACTORY_VISIT_REPORT_PERM =
  "organizations.delete_factoryvisitreport";
export const VIEW_FACTORY_VISIT_REPORT_PERM =
  "organizations.view_factoryvisitreport";

// Organizations ContactPerson
export const CREATE_CONTACT_PERSON_PERM = "organizations.add_contactperson";
export const EDIT_CONTACT_PERSON_PERM = "organizations.change_contactperson";
export const DELETE_CONTACT_PERSON_PERM = "organizations.delete_contactperson";
export const VIEW_CONTACT_PERSON_PERM = "organizations.view_contactperson";

// Users Profile
export const CREATE_PROFILE_PERM = "users.add_profile";
export const EDIT_PROFILE_PERM = "users.change_profile";
export const DELETE_PROFILE_PERM = "users.delete_profile";
export const VIEW_PROFILE_PERM = "users.view_profile";
export const EDIT_PROFILE_ADMIN_COMMENT_PERM =
  "users.can_edit_profile_admin_comment";
export const CAN_VIEW_ORGANIZATION_STATS_PERM =
  "organizations.can_view_organization_stats";
export const CAN_VIEW_USER_PERMISSIONS_PERM = "users.can_view_user_permissions";
export const CAN_MANAGE_USER_PERMISSIONS_PERM =
  "users.can_manage_user_permissions";
export const CAN_ASSIGN_SUPER_USER_PERM = "users.can_assign_super_user";

// Users Group
export const CREATE_GROUP_PERM = "auth.add_group";
export const EDIT_GROUP_PERM = "auth.change_group";
export const DELETE_GROUP_PERM = "auth.delete_group";
export const VIEW_GROUP_PERM = "auth.view_group";
export const CAN_MANAGE_GROUP_PERMISSIONS_PERM = "users.can_manage_group_permissions";
export const CAN_MANAGE_GROUP_USERS = "users.can_manage_group_users";

// Users UserInvitation
export const CREATE_USER_INVITATION_PERM = "users.add_userinvitation";
export const EDIT_USER_INVITATION_PERM = "users.change_userinvitation";
export const DELETE_USER_INVITATION_PERM = "users.delete_userinvitation";
export const VIEW_USER_INVITATION_PERM = "users.view_userinvitation";

// Forum FAQCategory
export const CREATE_FAQ_CATEGORY_PERM = "forum.add_faqcategory";
export const EDIT_FAQ_CATEGORY_PERM = "form.editfaqcategory";
export const DELETE_FAQ_CATEGORY_PERM = "forum.delete_faqcategory";
export const VIEW_FAQ_CATEGORY_PERM = "forum.view_faqcategory";

// FORUM FAQ
export const CREATE_FAQ_PERM = "forum.add_faq";
export const EDIT_FAQ_PERM = "forum.change_faq";
export const DELETE_FAQ_PERM = "forum.delete_faq";
export const VIEW_FAQ_PERM = "forum.view_faq";

// Billing OrganizationBilling
export const CREATE_ORGANIZATION_BILLING_PERM =
  "billing.add_organizationbilling";
export const EDIT_ORGANIZATION_BILLING_PERM =
  "billing.change_organizationbilling";
export const DELETE_ORGANIZATION_BILLING_PERM =
  "billing.delete_organizationbilling";
export const VIEW_ORGANIZATION_BILLING_PERM =
  "billing.view_organizationbilling";
export const ORGANIZATION_BILLING_STATS_PERM = "billing.can_view_billing_stats";

// Billing Service
export const CREATE_SERVICE_PERM = "billing.add_service";
export const EDIT_SERVICE_PERM = "billing.change_service";
export const DELETE_SERVICE_PERM = "billing.delete_service";
export const VIEW_SERVICE_PERM = "billing.view_service";

// Billing Payment
export const CREATE_PAYMENT_PERM = "billing.add_payment";
export const EDIT_PAYMENT_PERM = "billing.change_payment";
export const DELETE_PAYMENT_PERM = "billing.delete_payment";
export const VIEW_PAYMENT_PERM = "billing.view_payment";

// Reports VisitReportsExport
export const CREATE_VISIT_REPORTS_EXPORT_PERM =
  "reports.add_visitreportsexport";
export const EDIT_VISIT_REPORTS_EXPORT_PERM =
  "reports.change_visitreportsexport";
export const DELETE_VISIT_REPORTS_EXPORT_PERM =
  "reports.delete_visitreportsexport";
export const VIEW_VISIT_REPORTS_EXPORT_PERM = "reports.view_visitreportsexport";

// Support Room
export const CREATE_ROOM_PERM = "support.add_room";
export const EDIT_ROOM_PERM = "support.change_room";
export const DELETE_ROOM_PERM = "support.delete_room";
export const VIEW_ROOM_PERM = "support.view_room";

// Support Message
export const CREATE_MESSAGE_PERM = "support.add_message";
export const EDIT_MESSAGE_PERM = "support.change_message";
export const DELETE_MESSAGE_PERM = "support.delete_message";
export const VIEW_MESSAGE_PERM = "support.view_message";
