import { DELETE_ACTION_PLAN } from "./../../action.names";
import axios, { AxiosError } from "axios";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";

import { RootState } from "@/types/store.types";
import { ApiPaginationType, KeyObj } from "@/types/common.types";
import { buildParams, namespaced } from "@/store/utils";
import { NS_USER } from "@/store/namespace.names";
import {
  COURSES_ENDPOINT,
  DOWNLOAD_TRAINING_SESSION_ENDPOINT,
  TRAINING_SESSION_EVENT_ENDPOINT,
  FETCH_EVENTS_ENDPOINT,
  TRAINING_SESSION_ENDPOINT,
  PUBLIC_TRAINING_SESSION_ENDPOINT,
  PUBLIC_API
} from "@/store/endpoints";
import { AUTH_HEADER } from "@/store/getter.names";
import {
  TRAINING_SESSION_CREATE_OR_UPDATE,
  TRAINING_SESSION_EVENT_CREATE_OR_UPDATE,
  TRAINING_SESSION_FILE_UPLOAD,
  TRAINING_SESSION_FILES,
  TRAINING_SESSION,
  TRAINING_SESSION_DETAILS,
  PUBLIC_TEMPLATE_FILE,
  DOWNLOAD_MASTER_PLAN,
  DOWNLOAD_TRAINING_SESSION_DATA,
  FETCH_EVENTS,
  DELETE_EVENT,
  TRAINING_SESSION_FILE_DELETE,
  TRAINING_SESSION_DELETE
} from "@/store/action.names";
import type { Api } from "@/types/api.types";

const state: any = {};

const getters: GetterTree<KeyObj, RootState> = {};

const actions: ActionTree<KeyObj, RootState> = {
  async [PUBLIC_TEMPLATE_FILE](
    { rootGetters },
    {
      slug,
      params
    }: { slug: string; params: Api.PublicCoursesList.RequestQuery }
  ): Promise<Api.PublicCoursesFileList.ResponseBody> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${COURSES_ENDPOINT}/${slug}/file/${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [TRAINING_SESSION_CREATE_OR_UPDATE](
    { rootGetters },
    { payload, id }: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${PUBLIC_TRAINING_SESSION_ENDPOINT}/`;
      if (id !== undefined) {
        url = `${url}${id}/`;
        method = axios.patch;
      }
      method(url, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_DELETE]({ rootGetters }, { id }): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${PUBLIC_TRAINING_SESSION_ENDPOINT}/${id}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILE_UPLOAD](
    { rootGetters },
    { payload, onProgress, trainingId }: any
  ): Promise<Node | AxiosError> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${PUBLIC_TRAINING_SESSION_ENDPOINT}/${trainingId}/file/`,
          payload,
          {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                onProgress(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
              }
            },
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data as Node);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILES](
    { rootGetters },
    { trainingId, params }
  ): Promise<ApiPaginationType> {
    return new Promise((resolve, reject) => {
      // console.log(trainingId, params);
      axios
        .get(
          `${PUBLIC_TRAINING_SESSION_ENDPOINT}/${trainingId}/file/${buildParams(
            params
          )}`,
          { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] } }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_FILE_DELETE](
    { rootGetters },
    { trainingId, id }
  ): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${PUBLIC_TRAINING_SESSION_ENDPOINT}/${trainingId}/file/${id}/`,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_EVENT_CREATE_OR_UPDATE](
    { rootGetters },
    params: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = `${TRAINING_SESSION_EVENT_ENDPOINT}/`;
      if (params.id !== undefined) {
        url = `${url}${params.id}/`;
        method = axios.patch;
      }
      method(url, params.payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_EVENTS]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const method = axios.get;
      const url = `${FETCH_EVENTS_ENDPOINT}/${buildParams(params)}`;
      method(url, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_EVENT]({ rootGetters }, id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const method = axios.delete;
      const url = `${FETCH_EVENTS_ENDPOINT}/${id}/`;
      method(url, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_ACTION_PLAN]({ rootGetters }, id): Promise<KeyObj> {
    return new Promise((resolve, reject) => {
      const url = `${PUBLIC_API}/action-plans/${id}`;
      axios
        .delete(url, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION]({ rootGetters }, { params }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${TRAINING_SESSION_ENDPOINT}/${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DOWNLOAD_TRAINING_SESSION_DATA](
    { rootGetters },
    params: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${DOWNLOAD_TRAINING_SESSION_ENDPOINT}/${buildParams(params)}`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [TRAINING_SESSION_DETAILS]({ rootGetters }, { id }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${TRAINING_SESSION_ENDPOINT}/${id}/`, {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<KeyObj> = {};

const publicTrainingData: Module<KeyObj, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state
};

export default publicTrainingData;
