import { FETCH_ENROLLMENT_MODULE_INDEX } from './../../action.names';

import type { Api } from "./../../../types/api.types";
import vue from "vue";
import axios, { AxiosError } from "axios";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";

import {
  PaginatedPublicCourseListList,
  PublicEnrollment,
  PublicListEnrollment
} from "@/types/api.types";
import { PublicCourseDataState, RootState } from "@/types/store.types";

import {
  COURSES_ENDPOINT,
  ENROLLMENT_ENDPOINT,
  PRESENTATION_URL,
  PUBLIC_EXAM,
  REVIEWABLE_URL,
  TRAINER_CERTIFICATE
} from "@/store/endpoints";

import {
  COURSES,
  ENROLLMENTS,
  ENROLLMENT,
  COURSE_MODULES,
  AUTH_HEADER
} from "@/store/getter.names";
import {
  GET_COURSE_BY_SLUG,
  SEARCH_COURSES,
  FETCH_COURSES,
  RE_ENROLL,
  ADD_STATUS,
  FETCH_EXAM_SHEET,
  VALIDATE_EXAM_ANSWER,
  GO_PREVIOUS_LESSON,
  FETCH_LESSON,
  FETCH_PRESENTABLE_COURSE_MODULES,
  FETCH_PRESENTABLE_COURSE_CONTENTS,
  FETCH_ENROLLMENT,
  FETCH_ENROLLMENT_CONTENT_INDEX,
  FETCH_COURSES_MODULES,
  FETCH_COURSE_NEXT_CONTENT,
  FETCH_COURSE_BY_SLUG,
  PUBLIC_TRAINER_CERTIFICATE,
  GET_TRAINING_DATA,
  SUBMIT_COURSE_FEEDBACK,
  SUBMIT_QUESTION,
  DISABLE_EXAM_CONTENT,
  FETCH_REVIEWABLE_COURSE_MODULES,
  FETCH_REVIEWABLE_COURSE_CONTENTS,
  SEARCH_COURSES_INPLACE,
  UPDATE_RATING,
  UPDATE_ADMIN_RATING,
  FETCH_COURSE_MANUALS,
  FETCH_COURSE_FEEDBACK_QUESTIONS
} from "@/store/action.names";
import { NS_USER } from "@/store/namespace.names";
import {
  SET_COURSES,
  SET_COURSE_BY_SLUG,
  SET_ENROLLMENTS,
  SET_COURSE_SEARCH_RESULTS,
  SET_ENROLLMENT,
  SET_COURSE_MODULES
} from "../../mutation.names";

import { buildParams, namespaced } from "@/store/utils";

const state: PublicCourseDataState = {
  courses: null,
  searchResults: null,
  fullCourses: {},
  enrollments: {
    data: [],
    count: null,
    next: null,
    previous: null
  },
  fullEnrollments: {},
  courseModules: {
    data: [],
    count: null,
    next: null,
    previous: null
  }
};

const getters: GetterTree<PublicCourseDataState, RootState> = {
  [COURSES](
    state: PublicCourseDataState
  ): PaginatedPublicCourseListList | null {
    return state.courses;
  },
  [GET_COURSE_BY_SLUG]: state => (slug: string) => state.fullCourses[slug],

  [ENROLLMENTS](state: PublicCourseDataState): PublicEnrollment[] {
    return state.enrollments.data;
  },
  [ENROLLMENT]: state => (id: number) => state.fullEnrollments[id],
  [COURSE_MODULES](state: PublicCourseDataState): Array<any> {
    return state.courseModules.data;
  }
  // [COURSE_SEARCH_RESULTS](
  //   state: PublicCourseDataState
  // ): PublicCourseDataState | null {
  //   return state.searchResults;
  // },
};

const actions: ActionTree<PublicCourseDataState, RootState> = {
  [FETCH_COURSES]({ commit, state, rootGetters }, params: any = {}) {
    // console.log("working");
    axios
      .get(COURSES_ENDPOINT + "/", {
        params,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
      })
      .then(({ data }) => {
        commit(SET_COURSES, { data });
      });
    // .catch((e: any) => {
    //   console.log(e);
    // });
  },
  async [SEARCH_COURSES](
    { commit, state, rootGetters },
    params: any = {}
  ): Promise<any> {
    // const url = state.courses.data.length == 0 && state.courses.next == null ? COURSES_ENDPOINT : state.courses.next;
    return new Promise((resolve, reject) => {
      if (params.search == undefined) {
        resolve(null);
      }
      const url = COURSES_ENDPOINT;
      if (url != null) {
        axios
          .get(url, {
            params,
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          })
          .then(({ data }) => {
            // console.log(data);
            if (data.count == 0) {
              data = null;
            }
            commit(SET_COURSE_SEARCH_RESULTS, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  async [SEARCH_COURSES_INPLACE](
    { commit, state, rootGetters },
    params: any = {}
  ): Promise<any> {
    // const url = state.courses.data.length == 0 && state.courses.next == null ? COURSES_ENDPOINT : state.courses.next;
    return new Promise((resolve, reject) => {
      if (params.search == undefined) {
        resolve(null);
      }
      const url = COURSES_ENDPOINT;
      if (url != null) {
        axios
          .get(url, {
            params,
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          })
          .then(({ data }) => {
            // console.log(data);
            if (data.count == 0) {
              data = null;
            }
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  async [FETCH_COURSE_BY_SLUG](
    { commit, rootGetters, getters },
    { slug, refresh }
  ): Promise<Api.PublicCoursesRetrieve.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      const cache = getters[GET_COURSE_BY_SLUG](slug);
      if (!refresh && cache !== undefined) {
        resolve(cache);
      } else {
        axios
          .get(`${COURSES_ENDPOINT}/${slug}/`, {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          })
          .then(({ data }) => {
            commit(SET_COURSE_BY_SLUG, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },

  [FETCH_ENROLLMENT](
    { commit, rootGetters, getters },
    { enrollmentID, refresh }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const cache = getters[ENROLLMENT](enrollmentID);
      if (!refresh && cache !== undefined) {
        resolve(cache);
      } else {
        axios
          .get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/`, {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          })
          .then(({ data }) => {
            commit(SET_ENROLLMENT, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  async [FETCH_ENROLLMENT_CONTENT_INDEX](
    { commit, rootGetters, getters },
    { enrollmentID }
  ): Promise<any> {
    const { data } = await axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/content_index/`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    })
    return data;
  },
  async [FETCH_ENROLLMENT_MODULE_INDEX](
    { commit, rootGetters, getters },
    { enrollmentID, moduleID }
  ): Promise<any> {
    const { data } = await axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/module_index/?module=${moduleID}`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    })
    return data;
  },
  [RE_ENROLL]({ commit, rootGetters }, enrollmentID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/re_enroll`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          commit(SET_ENROLLMENT, data);
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_COURSES_MODULES]({ commit, rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${COURSES_ENDPOINT}/${slug}/modules`;
      axios
        .get(url, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          commit(SET_COURSE_MODULES, { data });
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [FETCH_EXAM_SHEET]({ rootGetters }, { payload, examID }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PUBLIC_EXAM}/${examID}/get_question_sheet/${buildParams(payload)}`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [VALIDATE_EXAM_ANSWER]({ rootGetters }, { payload, examID }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PUBLIC_EXAM}/${examID}/validate/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [GO_PREVIOUS_LESSON]({ rootGetters }, { payload, examID }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PUBLIC_EXAM}/${examID}/previous_lesson/`, payload, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  [FETCH_LESSON]({ rootGetters }, { course, lesson }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${COURSES_ENDPOINT}/${course}/lesson/${lesson}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [FETCH_COURSE_NEXT_CONTENT]({ rootGetters }, { slug }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${COURSES_ENDPOINT}/${slug}/next_content/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  //Presentable lessons
  async [FETCH_PRESENTABLE_COURSE_MODULES](
    { rootGetters },
    slug: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PRESENTATION_URL}/${slug}/modules/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [FETCH_PRESENTABLE_COURSE_CONTENTS](
    { rootGetters },
    { slug, id, content_id }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${PRESENTATION_URL}/${slug}/modules/${id}/content/?content_id=${content_id}`,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [FETCH_REVIEWABLE_COURSE_MODULES](
    { rootGetters },
    slug: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${REVIEWABLE_URL}/${slug}/modules/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async [FETCH_REVIEWABLE_COURSE_CONTENTS](
    { rootGetters },
    { slug, id, content_id }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${REVIEWABLE_URL}/${slug}/modules/${id}/content/?content_id=${content_id}`,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  [PUBLIC_TRAINER_CERTIFICATE](
    { commit, rootGetters },
    { uuid }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${TRAINER_CERTIFICATE}/${uuid}/public_certificate/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [ADD_STATUS](
    { rootGetters, dispatch },
    { enrollmentID, status }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/add_status/`, status, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          dispatch(FETCH_ENROLLMENT, {
            enrollmentID,
            refresh: true
          });
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [DISABLE_EXAM_CONTENT](
    { rootGetters },
    { enrollmentId, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ENROLLMENT_ENDPOINT}/${enrollmentId}/failed_exam_retake/`,
          payload,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  


  async [GET_TRAINING_DATA](
    { rootGetters },
    { enrollment_id, params }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ENROLLMENT_ENDPOINT}/${enrollment_id}/training-staff/${buildParams(
            params
          )}`,
          {
            headers: {
              ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_COURSE_FEEDBACK_QUESTIONS]({ rootGetters }, { slug }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${COURSES_ENDPOINT}/${slug}/feedback/question/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [SUBMIT_COURSE_FEEDBACK]({ rootGetters }, { slug, payload }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${COURSES_ENDPOINT}/${slug}/feedback/question/submit_answer/`,
          payload,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  [UPDATE_RATING]({ rootGetters }, { enrollment_id, rating }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${ENROLLMENT_ENDPOINT}/${enrollment_id}/update_rating/`,
          {rating},
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    })
  },

  [SUBMIT_QUESTION](
    { rootGetters },
    { enrollment_id, content_id, payload }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(enrollment_id, content_id, payload);
      axios
        .post(
          `${ENROLLMENT_ENDPOINT}/${enrollment_id}/${content_id}/collect-question/`,
          payload,
          {
            headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  async [FETCH_COURSE_MANUALS]({ rootGetters }, slug: string): Promise<any> {
    const { data } = await axios.get(`${COURSES_ENDPOINT}/${slug}/manuals/`, {
      headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
    });
    return data;
  }
};

const mutations: MutationTree<PublicCourseDataState> = {
  [SET_COURSES](state: any, payload) {
    state.courses = payload.data;
  },
  [SET_COURSE_BY_SLUG](state, payload) {
    vue.set(state.fullCourses, payload.slug, payload);
    // state.courses = payload.data
  },
  [SET_ENROLLMENTS](state: any, payload) {
    state.enrollments.data = payload.data.results;
    state.enrollments.count = payload.data.count;
    state.enrollments.next = payload.data.next;
    state.enrollments.previous = payload.data.previous;
  },
  [SET_ENROLLMENT](state, payload) {
    vue.set(state.fullEnrollments, payload.id, payload);
  },
  [SET_COURSE_MODULES](state: any, payload) {
    state.courseModules.data = payload.data.results;
    state.courseModules.count = payload.data.count;
    state.courseModules.next = payload.data.next;
    state.courseModules.previous = payload.data.previous;
  },
  [SET_COURSE_SEARCH_RESULTS](state: any, payload) {
    state.searchResults = payload;
  }
};

const publicCourseData: Module<PublicCourseDataState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state
};

export default publicCourseData;
