import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AdminRoutes from "./admin";
import CommonRoutes from "./common";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...AdminRoutes,
  ...CommonRoutes,
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      accessLevel: null
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
