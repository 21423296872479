import { ActionTree, Module } from "vuex";
import { RootState } from "@/types/store.types";
import { KeyObj } from "@/types/common.types";
import {
  ADD_PERMISSION_TO_GROUP,
  ADD_PERMISSION_TO_USER,
  ADD_USERS_TO_GROUP,
  CREATE_OR_UPDATE_GROUP,
  DELETE_GROUP,
  FETCH_GROUP,
  FETCH_GROUPS,
  FETCH_USER_PERMISSIONS,
  GET_USER,
  GET_USERS,
  REMOVE_PERMISSION_FROM_GROUP,
  REMOVE_PERMISSION_FROM_USER,
  REMOVE_USER_FROM_GROUP,
  SET_ADMIN_COMMENT,
  UPDATE_SUPERUSER_STATUS,
  UPDATE_USER_ADMIN
} from "@/store/action.names";
import Axios, { AxiosError } from "axios";
import { ADMIN_GROUP_ENDPOINT, ADMIN_USERS_URL } from "@/store/endpoints";
import { buildParams, namespaced } from "@/store/utils";
import { NS_USER } from "@/store/namespace.names";
import { AUTH_HEADER } from "@/store/getter.names";
import { Api } from "@/types/api.types";

const actions: ActionTree<any, RootState> = {
  async [CREATE_OR_UPDATE_GROUP]({ rootGetters }, payload: KeyObj) {
    let method = Axios.post;
    let url = `${ADMIN_GROUP_ENDPOINT}/`;
    if (payload.id) {
      method = Axios.put;
      url += `${payload.id}/`;
      delete payload.id;
    }
    const { data } = await method(url, payload, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },
  async [DELETE_GROUP]({ rootGetters }, id: number) {
    const { data } = await Axios.delete(`${ADMIN_GROUP_ENDPOINT}/${id}/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },
  async [FETCH_GROUPS]({ rootGetters }, params: KeyObj = {}) {
    const { data } = await Axios.get(
      `${ADMIN_GROUP_ENDPOINT}/${buildParams(params)}`,
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [FETCH_GROUP]({ rootGetters }, id: number) {
    const { data } = await Axios.get(`${ADMIN_GROUP_ENDPOINT}/${id}/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },
  async [ADD_PERMISSION_TO_GROUP](
    { rootGetters },
    { id, permission }: { id: number; permission: string }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_GROUP_ENDPOINT}/${id}/add_permission/`,
      { permission },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [REMOVE_PERMISSION_FROM_GROUP](
    { rootGetters },
    { id, permission }: { id: number; permission: string }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_GROUP_ENDPOINT}/${id}/remove_permission/`,
      { permission },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [ADD_USERS_TO_GROUP](
    { rootGetters },
    { id, users }: { id: number; users: number[] }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_GROUP_ENDPOINT}/${id}/add_users/`,
      { users },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [REMOVE_USER_FROM_GROUP](
    { rootGetters },
    { id, user }: { id: number; user: number }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_GROUP_ENDPOINT}/${id}/remove_user/`,
      { user },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [GET_USERS](
    { rootGetters },
    params: Api.AdminUsersList.RequestQuery = {}
  ): Promise<Api.AdminUsersList.ResponseBody | AxiosError> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_USERS_URL}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async [GET_USER](
    { rootGetters },
    slug: string
  ): Promise<Api.AdminUsersRetrieve.ResponseBody | AxiosError> {
    const { data } = await Axios.get(`${ADMIN_USERS_URL}/${slug}/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },

  async [UPDATE_USER_ADMIN](
    { rootGetters },
    {
      id,
      formData
    }: {
      id: number;
      formData:
        | Api.AdminUsersUpdate.RequestBody
        | Api.AdminUsersPartialUpdate.RequestBody;
    }
  ): Promise<Api.AdminUsersUpdate.ResponseBody | AxiosError> {
    const method = Axios.patch;
    const url = `${ADMIN_USERS_URL}/${id}/`;

    const { data } = await method(url, formData, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },
  async [UPDATE_SUPERUSER_STATUS]({ rootGetters }, payload: any) {
    return new Promise((resolve, reject) => {
      Axios
        .patch(`${ADMIN_USERS_URL}/${payload.id}/toggle_superuser/`, payload.data, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          // console.log(e);
          reject(e);
        });
    });
  },
  async [SET_ADMIN_COMMENT](
    { rootGetters },
    {
      id,
      comment
    }: {
      id: number;
      comment: string;
    }
  ): Promise<Api.AdminUsersUpdate.ResponseBody | AxiosError> {
    const method = Axios.patch;
    const url = `${ADMIN_USERS_URL}/${id}/edit_admin_comment/`;

    const { data } = await method(
      url,
      { admin_comment: comment },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [FETCH_USER_PERMISSIONS](
    { rootGetters },
    id: number
  ): Promise<Api.AdminUsersRetrieve.ResponseBody | AxiosError> {
    const { data } = await Axios.get(`${ADMIN_USERS_URL}/${id}/permissions/`, {
      headers: {
        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
      }
    });
    return data;
  },

  async [ADD_PERMISSION_TO_USER](
    { rootGetters },
    { id, permission }: { id: number; permission: string }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_USERS_URL}/${id}/add_permission/`,
      { permission },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  },
  async [REMOVE_PERMISSION_FROM_USER](
    { rootGetters },
    { id, permission }: { id: number; permission: string }
  ) {
    const { data } = await Axios.patch(
      `${ADMIN_USERS_URL}/${id}/remove_permission/`,
      { permission },
      {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)]
        }
      }
    );
    return data;
  }
};

const AccessControlStore: Module<KeyObj, RootState> = {
  namespaced: false,
  actions
};

export default AccessControlStore;
